const dataTableau = [
  {
    "id": 1001,
    "mtlogin": "mtuser001",
    "full_name": "Alice Smith",
    "retentionagentname": "agentA",
    "is_ftd": true,
    "country": "USA",
    "status": "Active",
    "pool": "North America",
    "created_date": "2024-01-15T09:30:00.000Z",
    "converted_date": "2024-02-20T10:45:00.000Z",
    "ftd_date": "2024-03-10T11:00:00.000Z",
    "actual_client_ip": "192.168.1.10",
    "verified": "YES",
    "unique_id": "ABC123XYZ",
    "totaldepositamount": 1500.75,
    "laststatusdate": "2024-04-05T14:20:00.000Z",
    "totalwithdrawalamount": 500.50,
    "lastcalldate": "2024-04-10T16:30:00.000Z",
    "firstcalldate": "2024-01-20T10:00:00.000Z",
    "city": "New York",
    "state": "NY",
    "gender": "Female",
    "initial_martial_status": "Single",
    "initial_experience": true,
    "initial_expectations": "High returns",
    "initial_bonus": "Welcome Bonus",
    "initial_experienced_on": "2024-01-15",
    "initial_experience_earn": true,
    "initial_how_to_contact": "Email",
    "mt_last_online": "2024-04-10T16:35:00.000Z",
    "atompix_last_online": "2024-04-09T15:25:00.000Z",
    "dialer_id": 2001,
    "dialer_type": "TypeA"
  },
  {
    "id": 1002,
    "mtlogin": "mtuser002",
    "full_name": "Bob Johnson",
    "retentionagentname": "agentB",
    "is_ftd": false,
    "country": "Canada",
    "status": "Pending",
    "pool": "Canada East",
    "created_date": "2024-02-10T08:15:00.000Z",
    "converted_date": "2024-03-12T09:25:00.000Z",
    "ftd_date": "2024-04-18T10:35:00.000Z",
    "actual_client_ip": "192.168.1.11",
    "verified": "NO",
    "unique_id": "DEF456UVW",
    "totaldepositamount": 750.00,
    "laststatusdate": "2024-04-20T13:50:00.000Z",
    "totalwithdrawalamount": 300.00,
    "lastcalldate": "2024-04-19T12:40:00.000Z",
    "firstcalldate": "2024-02-15T09:10:00.000Z",
    "city": "Toronto",
    "state": "ON",
    "gender": "Male",
    "initial_martial_status": "Married",
    "initial_experience": false,
    "initial_expectations": "Stable growth",
    "initial_bonus": "Referral Bonus",
    "initial_experienced_on": "2024-02-10",
    "initial_experience_earn": false,
    "initial_how_to_contact": "Phone",
    "mt_last_online": "2024-04-19T12:45:00.000Z",
    "atompix_last_online": "2024-04-18T11:30:00.000Z",
    "dialer_id": 2002,
    "dialer_type": "TypeB"
  },
  {
    "id": 1003,
    "mtlogin": "mtuser003",
    "full_name": "Charlie Davis",
    "retentionagentname": "agentC",
    "is_ftd": true,
    "country": "UK",
    "status": "Inactive",
    "pool": "Europe",
    "created_date": "2024-03-05T07:00:00.000Z",
    "converted_date": "2024-04-10T08:20:00.000Z",
    "ftd_date": "2024-05-15T09:30:00.000Z",
    "actual_client_ip": "192.168.1.12",
    "verified": "YES",
    "unique_id": "GHI789RST",
    "totaldepositamount": 2500.00,
    "laststatusdate": "2024-05-20T17:10:00.000Z",
    "totalwithdrawalamount": 800.00,
    "lastcalldate": "2024-05-19T16:00:00.000Z",
    "firstcalldate": "2024-03-10T08:05:00.000Z",
    "city": "London",
    "state": "London",
    "gender": "Non-binary",
    "initial_martial_status": "Single",
    "initial_experience": true,
    "initial_expectations": "High returns",
    "initial_bonus": "Signup Bonus",
    "initial_experienced_on": "2024-03-05",
    "initial_experience_earn": true,
    "initial_how_to_contact": "SMS",
    "mt_last_online": "2024-05-19T16:05:00.000Z",
    "atompix_last_online": "2024-05-18T15:15:00.000Z",
    "dialer_id": 2003,
    "dialer_type": "TypeA"
  },
  {
    "id": 1004,
    "mtlogin": "mtuser004",
    "full_name": "Diana Evans",
    "retentionagentname": "agentD",
    "is_ftd": false,
    "country": "Australia",
    "status": "Active",
    "pool": "Australia",
    "created_date": "2024-04-01T06:45:00.000Z",
    "converted_date": "2024-05-05T07:55:00.000Z",
    "ftd_date": "2024-06-10T08:05:00.000Z",
    "actual_client_ip": "192.168.1.13",
    "verified": "NO",
    "unique_id": "JKL012MNO",
    "totaldepositamount": 1800.00,
    "laststatusdate": "2024-06-15T18:25:00.000Z",
    "totalwithdrawalamount": 600.00,
    "lastcalldate": "2024-06-14T17:20:00.000Z",
    "firstcalldate": "2024-04-06T07:15:00.000Z",
    "city": "Sydney",
    "state": "NSW",
    "gender": "Female",
    "initial_martial_status": "Married",
    "initial_experience": false,
    "initial_expectations": "Long-term investment",
    "initial_bonus": "Loyalty Bonus",
    "initial_experienced_on": "2024-04-01",
    "initial_experience_earn": false,
    "initial_how_to_contact": "Email",
    "mt_last_online": "2024-06-14T17:25:00.000Z",
    "atompix_last_online": "2024-06-13T16:35:00.000Z",
    "dialer_id": 2004,
    "dialer_type": "TypeC"
  },
  {
    "id": 1005,
    "mtlogin": "mtuser005",
    "full_name": "Ethan Foster",
    "retentionagentname": "agentE",
    "is_ftd": true,
    "country": "Germany",
    "status": "Pending",
    "pool": "Europe",
    "created_date": "2024-05-10T05:30:00.000Z",
    "converted_date": "2024-06-12T06:40:00.000Z",
    "ftd_date": "2024-07-18T07:50:00.000Z",
    "actual_client_ip": "192.168.1.14",
    "verified": "YES",
    "unique_id": "MNO345PQR",
    "totaldepositamount": 2200.00,
    "laststatusdate": "2024-07-20T19:30:00.000Z",
    "totalwithdrawalamount": 900.00,
    "lastcalldate": "2024-07-19T18:10:00.000Z",
    "firstcalldate": "2024-05-15T06:25:00.000Z",
    "city": "Berlin",
    "state": "Berlin",
    "gender": "Male",
    "initial_martial_status": "Single",
    "initial_experience": true,
    "initial_expectations": "Quick growth",
    "initial_bonus": "Referral Bonus",
    "initial_experienced_on": "2024-05-10",
    "initial_experience_earn": true,
    "initial_how_to_contact": "Phone",
    "mt_last_online": "2024-07-19T18:15:00.000Z",
    "atompix_last_online": "2024-07-18T17:25:00.000Z",
    "dialer_id": 2005,
    "dialer_type": "TypeB"
  },
  {
    "id": 1006,
    "mtlogin": "mtuser006",
    "full_name": "Fiona Green",
    "retentionagentname": "agentF",
    "is_ftd": false,
    "country": "Spain",
    "status": "Inactive",
    "pool": "Europe",
    "created_date": "2024-06-20T04:15:00.000Z",
    "converted_date": "2024-07-22T05:25:00.000Z",
    "ftd_date": "2024-08-25T06:35:00.000Z",
    "actual_client_ip": "192.168.1.15",
    "verified": "NO",
    "unique_id": "PQR678STU",
    "totaldepositamount": 1300.00,
    "laststatusdate": "2024-08-30T20:40:00.000Z",
    "totalwithdrawalamount": 400.00,
    "lastcalldate": "2024-08-29T19:50:00.000Z",
    "firstcalldate": "2024-06-25T05:35:00.000Z",
    "city": "Madrid",
    "state": "Community of Madrid",
    "gender": "Female",
    "initial_martial_status": "Divorced",
    "initial_experience": false,
    "initial_expectations": "Moderate returns",
    "initial_bonus": "None",
    "initial_experienced_on": "2024-06-20",
    "initial_experience_earn": false,
    "initial_how_to_contact": "SMS",
    "mt_last_online": "2024-08-29T19:55:00.000Z",
    "atompix_last_online": "2024-08-28T18:45:00.000Z",
    "dialer_id": 2006,
    "dialer_type": "TypeC"
  },
  {
    "id": 1007,
    "mtlogin": "mtuser007",
    "full_name": "George Harris",
    "retentionagentname": "agentG",
    "is_ftd": true,
    "country": "Italy",
    "status": "Active",
    "pool": "Europe",
    "created_date": "2024-07-15T03:00:00.000Z",
    "converted_date": "2024-08-18T04:10:00.000Z",
    "ftd_date": "2024-09-20T05:20:00.000Z",
    "actual_client_ip": "192.168.1.16",
    "verified": "YES",
    "unique_id": "STU901VWX",
    "totaldepositamount": 3000.00,
    "laststatusdate": "2024-09-25T21:50:00.000Z",
    "totalwithdrawalamount": 1200.00,
    "lastcalldate": "2024-09-24T20:30:00.000Z",
    "firstcalldate": "2024-07-20T04:20:00.000Z",
    "city": "Rome",
    "state": "Lazio",
    "gender": "Male",
    "initial_martial_status": "Married",
    "initial_experience": true,
    "initial_expectations": "High returns",
    "initial_bonus": "Premium Bonus",
    "initial_experienced_on": "2024-07-15",
    "initial_experience_earn": true,
    "initial_how_to_contact": "Email",
    "mt_last_online": "2024-09-24T20:35:00.000Z",
    "atompix_last_online": "2024-09-23T19:45:00.000Z",
    "dialer_id": 2007,
    "dialer_type": "TypeA"
  },
  {
    "id": 1008,
    "mtlogin": "mtuser008",
    "full_name": "Hannah Lee",
    "retentionagentname": "agentH",
    "is_ftd": false,
    "country": "Netherlands",
    "status": "Pending",
    "pool": "Europe",
    "created_date": "2024-08-05T02:30:00.000Z",
    "converted_date": "2024-09-10T03:40:00.000Z",
    "ftd_date": "2024-10-22T04:50:00.000Z",
    "actual_client_ip": "192.168.1.17",
    "verified": "NO",
    "unique_id": "VWX234YZA",
    "totaldepositamount": 1600.00,
    "laststatusdate": "2024-10-25T22:10:00.000Z",
    "totalwithdrawalamount": 450.00,
    "lastcalldate": "2024-10-24T21:20:00.000Z",
    "firstcalldate": "2024-08-10T03:25:00.000Z",
    "city": "Amsterdam",
    "state": "North Holland",
    "gender": "Female",
    "initial_martial_status": "Single",
    "initial_experience": false,
    "initial_expectations": "Steady growth",
    "initial_bonus": "Referral Bonus",
    "initial_experienced_on": "2024-08-05",
    "initial_experience_earn": false,
    "initial_how_to_contact": "Phone",
    "mt_last_online": "2024-10-24T21:25:00.000Z",
    "atompix_last_online": "2024-10-23T20:35:00.000Z",
    "dialer_id": 2008,
    "dialer_type": "TypeB"
  },
  {
    "id": 1009,
    "mtlogin": "mtuser009",
    "full_name": "Ian Martinez",
    "retentionagentname": "agentI",
    "is_ftd": true,
    "country": "Brazil",
    "status": "Active",
    "pool": "South America",
    "created_date": "2024-09-12T01:15:00.000Z",
    "converted_date": "2024-10-14T02:25:00.000Z",
    "ftd_date": "2024-11-28T03:35:00.000Z",
    "actual_client_ip": "192.168.1.18",
    "verified": "YES",
    "unique_id": "YZA567BCD",
    "totaldepositamount": 2750.00,
    "laststatusdate": "2024-11-30T23:40:00.000Z",
    "totalwithdrawalamount": 1000.00,
    "lastcalldate": "2024-11-29T22:30:00.000Z",
    "firstcalldate": "2024-09-17T02:20:00.000Z",
    "city": "São Paulo",
    "state": "São Paulo",
    "gender": "Male",
    "initial_martial_status": "Married",
    "initial_experience": true,
    "initial_expectations": "High returns",
    "initial_bonus": "VIP Bonus",
    "initial_experienced_on": "2024-09-12",
    "initial_experience_earn": true,
    "initial_how_to_contact": "Email",
    "mt_last_online": "2024-11-29T22:35:00.000Z",
    "atompix_last_online": "2024-11-28T21:45:00.000Z",
    "dialer_id": 2009,
    "dialer_type": "TypeC"
  },
  {
    "id": 1010,
    "mtlogin": "mtuser010",
    "full_name": "Julia Nguyen",
    "retentionagentname": "agentJ",
    "is_ftd": false,
    "country": "Singapore",
    "status": "Inactive",
    "pool": "Asia",
    "created_date": "2024-10-20T00:00:00.000Z",
    "converted_date": "2024-11-22T01:10:00.000Z",
    "ftd_date": "2024-12-30T02:20:00.000Z",
    "actual_client_ip": "192.168.1.19",
    "verified": "NO",
    "unique_id": "BCD890EFG",
    "totaldepositamount": 1400.00,
    "laststatusdate": "2024-12-05T00:50:00.000Z",
    "totalwithdrawalamount": 350.00,
    "lastcalldate": "2024-12-04T23:40:00.000Z",
    "firstcalldate": "2024-10-25T01:05:00.000Z",
    "city": "Singapore",
    "state": "Singapore",
    "gender": "Female",
    "initial_martial_status": "Single",
    "initial_experience": false,
    "initial_expectations": "Moderate returns",
    "initial_bonus": "Signup Bonus",
    "initial_experienced_on": "2024-10-20",
    "initial_experience_earn": false,
    "initial_how_to_contact": "SMS",
    "mt_last_online": "2024-12-04T23:45:00.000Z",
    "atompix_last_online": "2024-12-03T22:55:00.000Z",
    "dialer_id": 2010,
    "dialer_type": "TypeA"
  }
]

export default dataTableau;


import React, { useState, useEffect } from "react";
import ava1 from "../../../images/avataaars (1).svg";
import ava2 from "../../../images/avataaars (2).svg";
import ava3 from "../../../images/avataaars (3).svg";
import UpdateAdminByManager from "../../Components/Modals/UpdateAdminBymanager";
import AddAdminBymanager from "../../Components/Modals/AddAdminbymanager";
// import AddAdminByBossSupp from "../../Components/Modals/AddAdminByBossSupp";
import axios from "axios";
import config from "../../../config/config";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdminDetails,
  getModalUpdated,
  setModalUpdated,
} from "../../Redux/slices/admin.slice";
import { setInterdit } from "../../Redux/slices/actions.slice";
import { SVGICON } from "../../../jsx/constant/theme";
import useHeader from "../../../utils/useHeader"

const UsersListSeller = () => {
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
  const [allUsersBySadmin, setAllUsersBySadmin] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const modalUpdated = useSelector(getModalUpdated);
  const adminDetails = useSelector(getAdminDetails);
  const rangAdmin = adminDetails?.rang;
  const Desk = adminDetails?.desk;
  const { desk } = useParams();

  const dispatch = useDispatch();

  const Header = useHeader()

  const fetchUsers = () => {
    let obj = {
      desk: desk || Desk.join(", "),
      categories: "SELLER",
      profil: "",
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/Users_by_desk_categories`,
        obj,
        Header
      )
      .then((response) => {
        dispatch(setModalUpdated(false));
        setAllUsersBySadmin(response.data);
        setSelectedMemberId(selectedMemberId);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalUpdated) {
      fetchUsers();
      handleCardClick(selectedMemberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalUpdated]);

  const handleCardClick = (idusers) => {
    setSelectedMemberId(idusers);
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/getUserByPseudo`,
        { pseudo: idusers?.pseudo },
        Header
      )
      .then((response) => {
        setSelectedMemberId(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const ShowModalAddAdmin = () => {
    setShowAddAdmin(true); // Mettre à jour l'état pour afficher le modal
  };

  const closeModal = () => {
    setShowAddAdmin(false);
    fetchUsers(); // // Mettre à jour l'état pour cacher le modal
  };


  const ShowModalUpdateAdmin = () => {
    setShowUpdateAdmin(true);
  };

  const closeModalUpdate = () => {
    dispatch(setModalUpdated(true));
    setShowUpdateAdmin(false);
    // Mettre à jour l'état pour cacher le modal
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <div className="d-flex">
      <div
        className="w-50 ps-3 pt-3 col-6"
        style={{ borderRight: "1px #cdcdcd solid" }}
      >
        <div
          className="border btn text-black fw-bold fs-14 py-1 mb-2"
          style={{ backgroundColor: "#25e87f" }}
          onClick={() => ShowModalAddAdmin()}
        >
          <span className="pe-2 fs-4">+</span>Nouvelle utilisateur (SELLER)
        </div>
        <div className="">
          <input
            type="text"
            className="input-search-utilisateurs col-6 border p-2"
            placeholder="Search ..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div style={{ overflowY: "auto", maxHeight: "700px" }}>
          {[...allUsersBySadmin]
            ?.filter(
              (member) => member.pseudo?.toLowerCase().includes(searchTerm) // Assuming 'name' is the property you want to search by
            )
            ?.reverse()
            ?.sort((a, b) => a.pseudo.localeCompare(b.pseudo))
            ?.map((member) => {
              const avatars = [ava1, ava2, ava3];
              const randomAvatar =
                avatars[Math.floor(Math.random() * avatars?.length)];

              return (
                <div
                  key={member?.idusers}
                  className="col-8 m-2 d-flex align-items-center card-admin-params ms-0"
                  style={{
                    border:
                      selectedMemberId?.id === member?.id
                        ? "1px solid #25e87f"
                        : "1px solid #e6e6e6",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleCardClick(member)}
                >
                  <div className="col-2 text-center">
                    <img
                      src={member?.avatar || randomAvatar}
                      alt=""
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>
                  <div className="p-3">
                    <div className="text-black fs-16">{member?.pseudo}</div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`badge-list-admin badge-${member?.profil} text-white fs-12`}
                      >
                        {member?.profil}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="p-5 w-100">
        {selectedMemberId && (
          <div className="">
            <div className="d-flex align-items-center">
              <img
                src={selectedMemberId?.avatar || ava1}
                alt=""
                style={{ height: "90px" }}
              />
              <div className="fs-20 text-black mt-1 ms-2">
                {selectedMemberId?.pseudo}
              </div>
              <div
                className={`badge-list-admin badge-${selectedMemberId?.profil} text-white fs-12 ms-4 mt-1`}
              >
                {selectedMemberId?.profil}
              </div>
              <div
                className="pointer"
                onClick={() => {
                  if (rangAdmin < selectedMemberId?.rang) {
                    ShowModalUpdateAdmin();
                  } else {
                    dispatch(setInterdit(true));
                  }
                }}
              >
                {SVGICON.BiPencillIcon}
              </div>
            </div>
            <div className="fw-bold text-black mt-3 ps-4">
              information utilisateur
            </div>
            <div className="list-info-perso mt-4">
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Pseudo</div>
                <div className="col-4">{selectedMemberId?.pseudo}</div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Profil</div>
                <div className="col-4">{selectedMemberId?.profil}</div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Code 2FA</div>
                <div className="col-4">
                  {selectedMemberId?.authenticator?.substring(0, 15)}...
                </div>
                <div>
                  <div
                    className="btn-copy"
                    style={{ cursor: "pointer" }}
                    data-tip="Copy"
                    onClick={() => {
                      navigator?.clipboard?.writeText(
                        selectedMemberId?.authenticator
                      );
                      setIsCopied(true);
                      setTimeout(() => setIsCopied(false), 2000);
                    }}
                  >
                    {isCopied ? "Copy!" : SVGICON.BiCopyIcon}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Team leader</div>
                <div className="col-4">
                  {selectedMemberId?.tlead === null ||
                  selectedMemberId?.tlead === "" ||
                  (Array.isArray(selectedMemberId?.tlead) &&
                    selectedMemberId?.tlead.includes("null"))
                    ? "non fournis"
                    : selectedMemberId?.tlead}
                </div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Desks</div>
                <div className="col-4">
                  {JSON.stringify(selectedMemberId?.desk)}
                </div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Brands</div>
                <div className="col-4">
                  {JSON.stringify(selectedMemberId?.brand)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showAddAdmin && (
        <AddAdminBymanager show={showAddAdmin} closeModal={closeModal} />
      )}
      {showUpdateAdmin && (
        <UpdateAdminByManager
          show={showUpdateAdmin}
          closeModal={closeModalUpdate}
          user={selectedMemberId}
        />
      )}
    </div>
  );
};

export default UsersListSeller;

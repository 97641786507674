export const styleFilters = () => ({
  container: (provided) => ({
    ...provided,
    // width: "162px !important",
    fontSize: "0.8rem",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "30px",
    height: "auto", // Ajuste automatiquement la hauteur en fonction du contenu
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "auto",
    padding: "2px 12px",
    display: "flex",
    flexWrap: "wrap", // Permet le wrapping des valeurs sélectionnées
    overflow: "visible", // Assure que le contenu est visible
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "auto",
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "0.5rem",
    whiteSpace: "nowrap", // Empêche le wrapping du texte
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "0.8rem",
  }),
  menu: (provided) => ({
    ...provided,
    width: 220,
    zIndex: 300,
    maxHeight: "200px", // Limite la hauteur du menu pour garantir qu'il est entièrement visible
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px", // Limite la hauteur de la liste des options
    overflowY: "auto", // Ajoute un défilement si nécessaire
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "normal",
  }),
});

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SVGICON } from "../../../jsx/constant/theme";
import ExpensesBarChart from "../Graph/ExpensesBarChart";
import NewExpChart from "../Graph/NewExpChart2";
import ChartRadar from "../Graph/ChartRadar";
import avatar from "../../../images/avataaars.svg";
import avatar1 from "../../../images/avataaars (1).svg";
import FondPodiumOk from "../../../images/photo_2024-04-30_18-39-37.jpg";
import ImpressionBar from "../Graph/ImpressionBar2";
import TableauConvertion from "../Graph/TableauConvertion2";
import GraphRatioByAgent from "../Graph/GraphRatioByAgent2";
import GraphMoyenneParAgent from "../Graph/GraphMoyenneParAgent";
import ProgressBarDesk from "../Graph/ProgressBarDesk";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row } from "react-bootstrap";
import GraphDernierContrat from "../Graph/GraphDernierContrat3";
import GraphNombreContrat from "../Graph/GraphNombreContrat4";
import TableauGeneral from "../Graph/TableauGeneral";
import formatNumber from "../../../utils/formatNumber";
import axios from "axios";
import config from "../../../config/config";
import useHeaderRest from "../../../utils/useHeader";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import CountUp from "react-countup";
import LineChartEncaisse from "../../Manager/Graph/LineChartEncaisse";
import TableauAgentGlobal from "./TableauAgentGlobal.js";
import GraphLastContractsByDay from "../Graph/GraphLastContractsByDay";
import GraphAverageRetap from "../../Manager/Graph/GraphAverageRetap";
import GraphRetraitByBrand from "../../Manager/Graph/GraphRetraitByBrand.js";
import {
  DataSignedEncashedByDesk,
  DataConversion,
  DataRatioAgent,
  DataDailyContracts,
  DataMonthlyContracts,
  dataWeeklyTableByDesk,
  targetByDesk,
  brutnetPrev,
  prevVsCurrentMonthByAgent,
  retraitSheet,
  GraphEncashedMonthlyByDesk,
  GraphMoyenneAgent,
  GraphLastcontratByday,
  AverageRetap,
  getRetraitByBrand,
} from "./DeskDetailFunctions";
import DeskDetailCard from "./DeskDetailCard";
import DeskDetailPodium from "./DeskDetailPodium";
import DeskDetailBrutNet from "./DeskDetailBrutNet";

const DeskDetail = () => {
  const useHeader = useHeaderRest();

  const { desk } = useParams();
  const deskIdAsNumber = parseInt(desk, 10);
  const GET_SOCKETS = useSelector(getSockets);
  const GET_ADMIN_DESKS = useSelector(getAdminDesks);

  const [dataSignedEncashed, setDataSignedEncashed] = useState();
  const [dataConversion, setDataConversion] = useState();
  const [dataRatioAgent, setDataRatioAgent] = useState();
  const [dataDailyContracts, setDataDailyContracts] = useState();
  const [dataMonthlyContracts, setDataMonthlyContracts] = useState();
  const [dataWeeklyTable, setDataWeeklyTable] = useState();
  const [dataTargetByDesk, setDataTargetByDesk] = useState();
  const [dataBrutNetPrev, setDataBrutNetPrev] = useState();
  const [dataPrevVsCurrentMonthByAgent, setDataPrevVsCurrentMonthByAgent] =
    useState();
  const [
    dataPrevVsCurrentMonthByAgentTotal,
    setDataPrevVsCurrentMonthByAgentTotal,
  ] = useState();
  const [dataRetraitSheet, setDataRetraitSheet] = useState();
  const [dataGraphEncashed, setDataGraphEncashed] = useState();

  const [averageByAgent, setAverageByAgent] = useState();
  const [lastContractsByDay, setLastContractsByDay] = useState();

  const [goodDesk, setGoodDesk] = useState();

  const [previousValue, setPreviousValue] = useState(0); // Valeur de départ
  const [currentValue, setCurrentValue] = useState(0); // Valeur finale
  const [isLoaded, setIsLoaded] = useState(false);

  const [previousValueEncaisse, setPreviousValueEncaisse] = useState(0); // Valeur de départ
  const [currentValueEncaisse, setCurrentValueEncaisse] = useState(0); // Valeur finale
  const [isLoadedEncaisse, setIsLoadedEncaisse] = useState(false);

  const [previousValuePreuve, setPreviousValuePreuve] = useState(0); // Valeur de départ
  const [currentValuePreuve, setCurrentValuePreuve] = useState(0); // Valeur finale
  const [isLoadedPreuve, setIsLoadedPreuve] = useState(false);

  const [averageRetap, setAverageRetap] = useState();
  const [retraitByBrand, setRetraitByBrand] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setGoodDesk(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desk]);

  useEffect(() => {
    if (goodDesk) {
      DataSignedEncashedByDesk(
        goodDesk,
        setDataSignedEncashed,
        setPreviousValueEncaisse,
        setCurrentValueEncaisse,
        setIsLoadedEncaisse,
        setPreviousValuePreuve,
        setCurrentValuePreuve,
        setIsLoadedPreuve,
        currentValueEncaisse,
        currentValuePreuve,
        useHeader
      );
      DataConversion(goodDesk, setDataConversion, useHeader);
      DataRatioAgent(goodDesk, setDataRatioAgent, useHeader);
      DataDailyContracts(goodDesk, setDataDailyContracts, useHeader);
      DataMonthlyContracts(goodDesk, setDataMonthlyContracts, useHeader);
      dataWeeklyTableByDesk(goodDesk, setDataWeeklyTable, useHeader);
      targetByDesk(goodDesk, setDataTargetByDesk, useHeader);
      brutnetPrev(
        goodDesk,
        setDataBrutNetPrev,
        setPreviousValue,
        setCurrentValue,
        setIsLoaded,
        currentValue,
        useHeader
      );
      prevVsCurrentMonthByAgent(
        goodDesk,
        setDataPrevVsCurrentMonthByAgent,
        setDataPrevVsCurrentMonthByAgentTotal,
        useHeader
      );
      retraitSheet(goodDesk, setDataRetraitSheet, useHeader);
      GraphEncashedMonthlyByDesk(goodDesk, setDataGraphEncashed, useHeader);
      GraphMoyenneAgent(goodDesk, setAverageByAgent, useHeader);
      GraphLastcontratByday(goodDesk, setLastContractsByDay, useHeader);
      AverageRetap(goodDesk, setAverageRetap, useHeader);
      getRetraitByBrand(goodDesk, setRetraitByBrand, useHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, goodDesk]);

  const calculateTotal = (data) => {
    const total_esperance = parseInt(data.total_esperance, 10) || 0;
    const total = 0 + total_esperance;
    return total.toLocaleString("fr-FR");
  };

  const handleChangePage = (mode) => {
    const filters = {
      desk: goodDesk,
      encaisse: 0,
      is_report: 0,
      is_canceled: 0,
    };

    if (mode === "preuve") {
      filters.preuve = 1;
    } else if (mode === "encaisse") {
      filters.encaisse = 1;
    }
    // Pour 'reste', aucun changement supplémentaire n'est nécessaire

    const obj = {
      filters: filters,
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
    };
    const deskToPath = goodDesk[0];
    // Décommentez et utilisez l'appel axios une fois que obj est correctement configuré
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        useHeader
      )
      .then((response) => {
        dispatchData(response.data.data.results);
        navigate(`/m_tab${deskToPath ? `/${deskToPath}` : ""}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatchData = (data) => dispatch(setAllContracts(data));

  const handleChangePageGraph = (mode, user) => {
    const filters = {
      desk: goodDesk,
      encaisse: 0,
      is_report: 0,
      is_canceled: 0,
      seller_id: user,
    };

    if (mode === "Report") {
      filters.is_report = 1;
    } else if (mode === "Preuve") {
      filters.preuve = 1;
    } else if (mode === "Encaisse") {
      filters.encaisse = 1;
    }
    // Pour 'reste', aucun changement supplémentaire n'est nécessaire
    const obj = {
      filters: filters,
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
    };
    const deskToPath = goodDesk[0];
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        useHeader
      )
      .then((response) => {
        dispatchData(response.data.data.results);
        navigate(`/m_tab${deskToPath ? `/${deskToPath}` : ""}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="row p-0">
        {dataTargetByDesk &&
          dataSignedEncashed &&
          dataRetraitSheet &&
          GET_ADMIN_DESKS.length === 1 &&
          dataBrutNetPrev && (
            <ProgressBarDesk
              data={dataTargetByDesk}
              totalencaisse={
                Number(dataBrutNetPrev?.brutNetPrev.brut_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount || 0) || 0
              }
              totalnetprev={
                Number(dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount || 0) || 0
              }
            />
          )}

        {dataTargetByDesk &&
          dataSignedEncashed &&
          desk &&
          dataBrutNetPrev &&
          dataRetraitSheet && (
            <ProgressBarDesk
              data={dataTargetByDesk}
              totalencaisse={
                Number(dataBrutNetPrev?.brutNetPrev.brut_previsionnel || 0) +
                  (Number(dataRetraitSheet[0].total_amount) || 0) || 0
              }
              totalnetprev={
                Number(dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount) || 0
              }
            />
          )}

        <div className="col-5">
          <div className="row">
            <div className="col-12 p-0">
              {dataWeeklyTable && (
                <TableauGeneral dataWeeklyData={dataWeeklyTable} />
              )}
            </div>
          </div>
        </div>

        <div className="col-7">
          <div className="row">
            <div className="col-4">
              <div className="card crm-cart">
                <div className="card-body h-50 p-1 py-4">
                  <DeskDetailBrutNet
                    isLoaded={isLoaded}
                    previousValue={previousValue}
                    currentValue={currentValue}
                    dataBrutNetPrev={dataBrutNetPrev}
                    dataRetraitSheet={dataRetraitSheet}
                    formatNumber={formatNumber}
                  />
                </div>
              </div>

              <div
                onClick={() => handleChangePage("reste")}
                className="card crm-cart pointer hover-effect"
                title="tous les contrats signes , sauf ceux qui sont plus petit que 2euros, et les annulés ( nous gardons donc les reportés ) "
              >
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Reste a encaissé</h5>
                </div>

                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    <p>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                        }}
                      >
                        {dataSignedEncashed &&
                          formatNumber(
                            dataSignedEncashed?.signed.sum_signed || 0
                          )}
                        €
                      </span>
                    </p>
                  </div>
                  <ImpressionBar />
                </div>

                <div className="row justify-content-between mt-3 mx-3">
                  <div
                    className="col-2 d-none"
                    title={` 
                    IL S'AGIT DE SUM SIGNED QUI EST PRISE ICI
                    SELECT 
                      COUNT(DISTINCT contracts.seller_id) AS distinct_users,
                      
                      COUNT(amount) as count_legit_contract,

                      COALESCE( SUM(
                        CASE 
                          WHEN encaisse != 1
                          AND is_report !=1
                          
                      THEN amount
                      ELSE 0 END), 0) as sum_signed,


                      SUM(encash_usd) as sum_encashed,
                      COALESCE(SUM(CASE
                            WHEN preuve = 1   
                            AND (encaisse IS NULL OR encaisse = 0)
                            AND (is_report IS NULL OR is_report = 0)          
                            THEN amount 
                            ELSE 0 END), 0) AS proof
                    FROM contracts
                    INNER JOIN users ON contracts.seller_id = users.idusers  AND users.is_active = 1  AND (users.profil = 'sell' OR users.profil = 'tlead')
                    WHERE desk IN (${desk})
                      AND contracts.is_active = 1
                      AND amount > 1
                      AND is_canceled !=1  `}
                  >
                    {SVGICON.BiCodeIcon}
                  </div>

                  <div
                    className="col-2 "
                    title={`En excluant ce qui est deja encaissé, et ce qui est annulé, vous trouverez ici tous les contrats  `}
                  >
                    {SVGICON.BiInfoIcon}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                onClick={() => handleChangePage("preuve")}
                className="card crm-cart pointer hover-effect"
                title=" ici nous prenons toutes les commandes ou la case 'preuve' a ete coché, il faut que la case encaissé soit vide, que la commande n'est pas ete annulé ni reporté, le contrats doit etre superieur a 1euros, et ne doit pas avoir été supprimé  "
              >
                <div className="card-body">
                  <div className="crm-cart-data">
                    <h5 className="d-block mb-1 text-black">
                      Total (en preuve)
                    </h5>
                    {isLoadedPreuve && (
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        <CountUp
                          start={previousValuePreuve} // Utiliser l'ancienne valeur
                          end={currentValuePreuve} // Utiliser la nouvelle valeur
                          duration={3.5} // Ajuster la durée si nécessaire
                          separator=" " // Nombre de décimales si nécessaire
                        />
                        $
                      </span>
                    )}
                  </div>
                </div>
                <NewExpChart color={"blue"} />
              </div>

              <div
                onClick={() => handleChangePage("encaisse")}
                className="card crm-cart pointer hover-effect"
                title="total encaissé , nous enlevons uniquement toutes les commandes plus petite que 2euros, et les contracts annulé"
              >
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Total (encaissé)</h5>
                  <div className="icon-box">{SVGICON.DollerBlack}</div>
                </div>
                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    {isLoadedEncaisse && (
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        <CountUp
                          start={previousValueEncaisse} // Utiliser l'ancienne valeur
                          end={currentValueEncaisse} // Utiliser la nouvelle valeur
                          duration={3.5} // Ajuster la durée si nécessaire
                          separator=" " // Nombre de décimales si nécessaire
                        />
                        $
                      </span>
                    )}
                  </div>
                  <ExpensesBarChart />
                </div>
              </div>
            </div>
            <div className="col-4">
              {dataRatioAgent && (
                <DeskDetailPodium
                  dataRatioAgent={dataRatioAgent}
                  formatNumber={formatNumber}
                  FondPodiumOk={FondPodiumOk}
                  avatar={avatar}
                  avatar1={avatar1}
                />
              )}

              <div>
                <Card className="crm-cart">
                  <div className="card-header border-0 pb-0 diposit-bg">
                    <h5 className="d-block text-black">Retraits en cours</h5>
                  </div>

                  <div className="card-body py-0">
                    <div className="crm-cart-data d-flex align-items-center">
                      <p>
                        <span
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "1.3rem",
                          }}
                        >
                          {dataRetraitSheet &&
                            formatNumber(dataRetraitSheet[0].total_amount)}
                          $
                        </span>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dataRatioAgent && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Ratio chiffre total par agent
                  </div>
                  <div className="col-5 d-flex justify-content-around align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Preuve me-1"></div>
                      <div>Preuve</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Signé me-1"></div>
                      <div>Signé</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Encaissé me-1"></div>
                      <div>Encaissé</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Report me-1"></div>
                      <div>Report</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Header>

            {dataRatioAgent && (
              <>
                <GraphRatioByAgent
                  height={"400px"}
                  arrEncaisse={dataRatioAgent?.map(
                    (agent) => agent?.sum_encashed
                  )}
                  arrSigned={dataRatioAgent?.map((agent) => agent?.totalSigned)}
                  arrProof={dataRatioAgent?.map((agent) => agent?.sum_proof)}
                  arrReported={dataRatioAgent?.map((agent) => agent?.reported)}
                  arrCanceled={dataRatioAgent?.map((agent) => agent?.canceled)}
                  // arrCategories={dataRatioAgent?.map((agent) => agent?.pseudo + " " + agent?.total_esperance)}
                  arrCategories={dataRatioAgent?.map((agent) => {
                    const total = calculateTotal(agent);
                    return { agent, total };
                  })}
                  goodDesk={goodDesk}
                  handlePageGraph={handleChangePageGraph}
                />
              </>
            )}
          </Card>
        </div>
      )}
      {averageByAgent && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Moyenne contrat par agent (uniquement les agents ayant faits
                    des contrats)
                  </div>
                </div>
              </div>
            </Card.Header>
            <GraphMoyenneParAgent height={"400px"} data={averageByAgent} />
          </Card>
        </div>
      )}
      {lastContractsByDay && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Jours écoulés depuis le dernier contrat
                  </div>
                </div>
              </div>
            </Card.Header>
            <GraphLastContractsByDay
              height={"400px"}
              data={lastContractsByDay}
            />
          </Card>
        </div>
      )}
      <Row className=" ">
        {dataPrevVsCurrentMonthByAgentTotal && (
          <DeskDetailCard
            dataPrevVsCurrentMonthByAgentTotal={
              dataPrevVsCurrentMonthByAgentTotal
            }
            formatNumber={formatNumber}
          />
        )}
      </Row>

      <TableauAgentGlobal
        dataPrevVsCurrentMonthByAgent={dataPrevVsCurrentMonthByAgent}
      />

      <Card className="col-12 p-0">
        <Card.Header>
          <div className="card-title col-12 p-0">
            <div className="d-flex align-iterms-center">
              <div className="text-start">Conversion par agent</div>
            </div>
          </div>
        </Card.Header>
        {dataConversion && (
          <TableauConvertion
            horizontal={false}
            data={dataConversion?.map(
              (conversion) => conversion?.sum_conversion
            )}
            arrCategories={dataConversion?.map(
              (conversion) => conversion?.pseudo
            )}
          />
        )}
      </Card>

      <div className="d-flex">
        <Col sm={6} className="p-0 pe-2">
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre Contrats(jour)</h4>
            </Card.Header>
            <Card.Body className="p-2 d-flex align-items-end">
              {dataDailyContracts && (
                <GraphDernierContrat
                  arrCategorie={dataDailyContracts?.map(
                    (agent) => agent?.pseudo
                  )}
                  arrData={dataDailyContracts?.map(
                    (agent) => agent?.total_contracts
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} className="ps-2 p-0">
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre contrats (mois)</h4>
            </Card.Header>

            <Card.Body className="p-2">
              {dataMonthlyContracts && (
                <GraphNombreContrat
                  arrData={dataMonthlyContracts?.map(
                    (agent) => agent?.total_contracts
                  )}
                  arrCategorie={dataMonthlyContracts?.map(
                    (agent) => agent?.pseudo
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </div>

      {retraitByBrand && (
        <div className="d-flex">
          <Col sm={12} className="p-0">
            <Card>
              <Card.Header>
                <h4 className="card-title">Chiffres signés par brand</h4>
              </Card.Header>
              <Card.Body className="p-2 d-flex align-items-end">
                <GraphRetraitByBrand data={retraitByBrand} />
              </Card.Body>
            </Card>
          </Col>
        </div>
      )}


      {dataRetraitSheet &&
        dataRetraitSheet.length > 0 &&
        goodDesk.length === 1 && (
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">Retrait par brand</div>
                </div>
              </div>
            </Card.Header>
            <ChartRadar dataPur={dataRetraitSheet} />
          </Card>
        )}


      {dataGraphEncashed && (
        <div className="d-flex">
          <Col sm={12} className="p-0">
            <Card>
              <Card.Header>
                <h4 className="card-title">
                  Encaissements par jour du/des Desk(s)
                </h4>
              </Card.Header>
              <Card.Body className="p-2 d-flex align-items-end">
                <LineChartEncaisse data={dataGraphEncashed} />
              </Card.Body>
            </Card>
          </Col>
        </div>
      )}

      <div className="">
        {averageRetap && (
          <div className="d-flex col p-0">
            <Card className="col-12 p-0">
              <Card.Header>
                <div className="card-title col-12">
                  <div className="d-flex align-items-center">
                    <div className="text-start col-6">
                      Moyenne en jour de retap par agent
                      <span
                        title="La requête calcule la moyenne de nombre de jours entre chaque encaissement pour ses clients. Si 0, les encaissements se sont faits le même jour."
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      >
                        {SVGICON.BiInfoIcon}
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <GraphAverageRetap height={"400px"} data={averageRetap} />
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default DeskDetail;

import React, { useState, useEffect } from "react";
import ava1 from "../../../images/avataaars (1).svg";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { setPopup } from "../../Redux/slices/actions.slice";
import { useDispatch } from "react-redux";
import formatNumber from "../../../utils/formatNumber";
import useHeader from "../../../utils/useHeader";

const UpdateTargetAdmin = ({ show, onHide, data }) => {
  const [ListAdminSelected, setListAdminSelected] = useState(data);
  const [updatingId, setUpdatingId] = useState(null);

  const initializeTargets = (members) => {
    return members.map((member) => {
      while (member.targets.length < 3) {
        member.targets.push({
          objectif: 0,
          cash: "",
          idtargets: member.idtargets,
          desk: member.desk,
          user_id: member.idusers,
        });
      }
      return member;
    });
  };

  const dispatch = useDispatch();
  const Header = useHeader()

  // Utilisez cette fonction pour initialiser les cibles lorsque vous recevez les données
  const ListAdmin = initializeTargets(data);

  useEffect(() => {
    setListAdminSelected(data);
  }, [data]);

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const handleInputChange = (event, memberIndex, targetIndex, field) => {
    // Créez une copie de ListAdminSelected
    const newListAdminSelected = [...ListAdminSelected];

    // Vérifiez que newListAdminSelected[memberIndex] et newListAdminSelected[memberIndex].targets[targetIndex] existent
    if (
      newListAdminSelected[memberIndex] &&
      newListAdminSelected[memberIndex].targets &&
      newListAdminSelected[memberIndex].targets[targetIndex]
    ) {
      const inputValue = event.target.value;
      if (field !== undefined) {
        newListAdminSelected[memberIndex].targets[targetIndex].objectif =
          inputValue;
      }
      // Vérifiez si la valeur contient uniquement des chiffres
      else if (/^\d+$/.test(inputValue)) {
        // Si la valeur contient uniquement des chiffres
        newListAdminSelected[memberIndex].targets[targetIndex].cash =
          inputValue;
        newListAdminSelected[memberIndex].targets[targetIndex].gift = 0;
      } else {
        // Si la valeur ne contient pas uniquement des chiffres
        newListAdminSelected[memberIndex].targets[targetIndex].cash = 0;
        newListAdminSelected[memberIndex].targets[targetIndex].gift =
          inputValue;
      }
      // Mettez à jour l'état de ListAdminSelected
      setListAdminSelected(newListAdminSelected);
    } else {
      // console.error(`Cannot update field because it does not exist`);
    }
  };

  const UpdateTargets = (member) => {
    let obj = {
      data: data[0].targets,
    };
    axios
      .put(
        `${config.apiAdmin}/sadmin/targets/create_or_update_by_array`,
        obj,
        Header
      )
      .then((response) => {
        onHide();
        SendPopup({
          message: "confirm",
          info: "create_or_update_by_array.",
          isActive: true,
        });
        setUpdatingId(member.idusers);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "create_or_update_by_array.",
          isActive: true,
        });
        onHide();
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Modal className="modal fade" show={show} onHide={onHide} centered>
        <Modal.Body
          style={{ backgroundColor: "#F4F4F5" }}
          className="border rounded"
        >
          <p className="fw-bold text-black fs-20">Paramétrage de target</p>
          <div
            className="mt-4"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {ListAdmin?.map((member, index) => (
              <>
                <div className="">
                  <div className="d-flex mt-2">
                    <div className="d-flex col-4 p-3">
                      <div>
                        <img
                          src={ava1}
                          alt=""
                          style={{ height: "45px", width: "45px" }}
                        />
                      </div>
                      <div className="mt-2 ms-2">
                        <div
                          className="fw-bold text-black"
                          style={{ fontSize: "16px" }}
                        >
                          {member?.firstName} {member?.lastName}
                        </div>
                        <div
                          className="text-black fw-bold"
                          style={{ fontSize: "16px" }}
                        >
                          {member?.pseudo}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center mb-3">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black mb-2"
                              style={{ fontSize: "12px" }}
                            >
                              Target 1
                            </label>
                            <input
                              type="text"
                              name="target1"
                              className="form-control text-center text-black"
                              id="input1"
                              style={{ height: "30px", fontSize: "12px" }}
                              defaultValue={formatNumber(
                                member?.targets?.[0]?.objectif
                              )}
                              onChange={(event) =>
                                handleInputChange(event, index, 0, "objectif")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black mb-2"
                              style={{ fontSize: "12px" }}
                            >
                              Target 2
                            </label>
                            <input
                              type="text"
                              name="target1"
                              className="form-control text-center text-black"
                              id="input1"
                              style={{ height: "30px", fontSize: "12px" }}
                              defaultValue={formatNumber(
                                member?.targets?.[1]?.objectif
                              )}
                              onChange={(event) =>
                                handleInputChange(event, index, 1, "objectif")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black mb-2"
                              style={{ fontSize: "12px" }}
                            >
                              Target 3
                            </label>
                            <input
                              type="text"
                              name="target1"
                              className="form-control text-center text-black"
                              id="input1"
                              style={{ height: "30px", fontSize: "12px" }}
                              defaultValue={formatNumber(
                                member?.targets?.[2]?.objectif
                              )}
                              onChange={(event) =>
                                handleInputChange(event, index, 2, "objectif")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              Gains
                            </label>
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              {member?.targets?.[0]?.cash === 0 ||
                              member?.targets?.[0]?.cash === undefined
                                ? member?.targets?.[0]?.gift
                                : member?.targets?.[0]?.cash + " $"}
                            </label>
                            <input
                              type="text"
                              name="gains1"
                              className="form-control text-center text-black my-1"
                              id="gains1"
                              style={{ height: "30px", fontSize: "12px" }}
                              onChange={(event) => {
                                handleInputChange(event, index, 0);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              Gains
                            </label>
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              {member?.targets?.[1]?.cash === 0 ||
                              member?.targets?.[1]?.cash === undefined
                                ? member?.targets?.[1]?.gift
                                : member?.targets?.[1]?.cash + " $"}
                            </label>
                            <input
                              type="text"
                              name="gains2"
                              className="form-control text-center text-black my-1"
                              id="gains2"
                              style={{ height: "30px", fontSize: "12px" }}
                              onChange={(event) => {
                                handleInputChange(event, index, 1);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group d-flex flex-column align-items-center">
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              Gains
                            </label>
                            <label
                              htmlFor="input1"
                              className="text-center w-100 m-0 text-black my-1"
                              style={{ fontSize: "12px" }}
                            >
                              {member?.targets?.[2]?.cash === 0 ||
                              member?.targets?.[2]?.cash === undefined
                                ? member?.targets?.[2]?.gift
                                : member?.targets?.[2]?.cash + " $"}
                            </label>
                            <input
                              type="text"
                              name="gains3"
                              className="form-control text-center text-black my-1"
                              id="gains3"
                              style={{ height: "30px", fontSize: "12px" }}
                              onChange={(event) => {
                                handleInputChange(event, index, 2);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-black mt-4">
                  <div className="fw-bold">
                    La case Gains vous donne 2 possibilités :
                  </div>
                  <div className="mt-3">
                    1 . Pour ajouter un montant, écrire la valeur en chiffres
                  </div>
                  <div>
                    2 . Pour ajouter un cadeaux, écrire en lettres ex: IPHONE
                  </div>
                  <div className="mt-3">Pour mettre à zero écrire 0.</div>
                </div>
                <div className="d-flex justify-content-center">
                  {updatingId === member.idusers ? (
                    <Button className="p-2 m-2 mt-4 rounded-1 fs-14 btn-update-target">
                      updated
                    </Button>
                  ) : (
                    <Button
                      className="py-2 px-5 mt-4 rounded-2 bg-green-login border-0 fs-14 text-black fw-bold"
                      onClick={() => UpdateTargets(member)}
                    >
                      valider
                    </Button>
                  )}
                </div>
              </>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateTargetAdmin;

import React, { useEffect, useState, useMemo } from "react";
import { Table, Card } from "react-bootstrap";
import useHeaderRest from "../../../utils/useHeader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import "bootstrap-icons/font/bootstrap-icons.css";

const Calls = () => {
  const [state, setState] = useState({
    sortConfig: { key: null, direction: null },
    filter: "",
    allCalls: [],
    loader: false,
  });

  const { sortConfig, filter, allCalls, loader } = state;
  const useHeader = useHeaderRest();
  const { desk } = useParams();
  const AdminDetail = useSelector(getAdminDetails);
  const deskAdmin = AdminDetail.desk;
  const deskValue = desk ? [desk] : deskAdmin;

  const fetchCalls = async () => {
    setState((prev) => ({ ...prev, loader: true }));
    try {
      const response = await axios.post(
        `${config.apiAdmin}/sadmin/whsheet/read_call`,
        { desk: deskValue },
        useHeader
      );
      setState((prev) => ({ ...prev, allCalls: response.data, loader: false }));
    } catch (error) {
      console.error("There was an error!", error);
      setState((prev) => ({ ...prev, allCalls: [], loader: false }));
    }
  };

  useEffect(() => {
    fetchCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedData = useMemo(() => {
    let sortable = [...allCalls];
    if (sortConfig.key) {
      sortable.sort((a, b) => {
        const aVal = sortConfig.key === "total_duration" ? Number(a[sortConfig.key]) : a[sortConfig.key];
        const bVal = sortConfig.key === "total_duration" ? Number(b[sortConfig.key]) : b[sortConfig.key];
        if (aVal < bVal) return sortConfig.direction === "ascending" ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }
    return sortable.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [allCalls, sortConfig, filter]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setState((prev) => ({ ...prev, sortConfig: { key, direction } }));
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig.key) return <i className="bi bi-arrow-down-up"></i>;
    if (sortConfig.key === name) {
      return sortConfig.direction === "ascending" ? <i className="bi bi-sort-up"></i> : <i className="bi bi-sort-down"></i>;
    }
    return <i className="bi bi-arrow-down-up"></i>;
  };

  return (
    <Card>
      <Card.Header className="fw-bold text-black fs-4">Calls</Card.Header>
      <div className="d-flex justify-content-between">
        <input
          placeholder="Recherche"
          value={filter}
          onChange={(e) => setState((prev) => ({ ...prev, filter: e.target.value }))}
          className="my-3 col-3 ms-5 p-2 rounded border"
        />
        <button
          className="btn btn-dark p-2 my-3 border-rounded fs-14 me-5"
          onClick={fetchCalls}
        >
          {loader ? "Loading" : "Refresh"}
        </button>
      </div>
      <Table bordered hover>
        <thead className="pointer">
          <tr>
            {["idsheet_calls", "brand_name", "desk", "agent", "agent_type", "clients_called", "total_calls", "avg_duration", "total_duration"].map((header) => (
              <th
                key={header}
                onClick={() => requestSort(header)}
                className={getClassNamesFor(header)}
              >
                {header} {getClassNamesFor(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{item.idsheet_calls}</td>
              <td>{item.brand_name}</td>
              <td>{item.desk}</td>
              <td>{item.agent}</td>
              <td>{item.agent_type}</td>
              <td>{item.clients_called}</td>
              <td>{item.total_calls}</td>
              <td>{Number(item.avg_duration).toFixed(2)}</td>
              <td>{Number(item.total_duration).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default Calls;
import { SVGICON } from "../../constant/theme";
import { useSelector } from "react-redux";
import { getAdminProfil } from "../../../PagesCrm/Redux/slices/admin.slice";

export const useMenuList = () => {
  const adminProfil = useSelector(getAdminProfil);

  if (adminProfil === "sadmin") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "sadmin",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "sadmin_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "sadmin_tab",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        contentMain: [
          { title: "Psp", to: "m_psp" },
          { title: "Platforme", to: "m_neobank" },
          { title: "Banque client", to: "m_bankclient" },
          { title: "Brand", to: "sadmin_Brand" },
          { title: "Desk", to: "sadmin_desk" },
        ],
        contentSub: [
          { title: "Utilisateurs", to: "m_params" },
          { title: "Targets", to: "m_targets" },
          { title: "Alerts", to: "m_alerts" },
        ],
      },
    ];
  } else if (adminProfil === "manager") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "m",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "m_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "m_tab",
      },
      {
        title: "Leads",
        iconStyle: SVGICON.IconLead,
        to: "m_leads",
      },
      {
        title: "Performance",
        iconStyle: SVGICON.CheckRight,
        content: [
          { title: "Journalière", to: "m_performance" },
          { title: "Mensuelle", to: "m_performance_monthly" },
        ],
        // to: "m_performance",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "m_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "m_retraits",
      },
      {
        title: "Documents",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Trello,
        to: "m_documents",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "m_params" },
          { title: "Targets", to: "m_targets" },
          { title: "Alerts", to: "m_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "m", iconStyle: SVGICON.Home },
          { title: "Statistiques", to: "m_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "m_tab", iconStyle: SVGICON.Forms },
          {
            title: "Performance",
            // to: "m_performance",
            iconStyle: SVGICON.CheckRight,
            content: [
              { title: "Journalière", to: "m_performance" },
              { title: "Mensuelle", to: "m_performance_monthly" },
            ],
          },
          { title: "Calls", to: "m_calls", iconStyle: SVGICON.CallIcon },
          { title: "Retraits", to: "m_retraits", iconStyle: SVGICON.Retraits },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "m_params" },
              { title: "Targets", to: "m_targets" },
              { title: "Alerts", to: "m_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "tlead") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "tl",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "tl_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "tl_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "tl_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "tl_retraits",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "tl_params", iconStyle: SVGICON.Forms },
          { title: "Targets", to: "tl_targets", iconStyle: SVGICON.Forms },
          { title: "Alerts", to: "tl_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "admin" },
          { title: "Statistiques", to: "tl_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "tl_tab", iconStyle: SVGICON.Forms },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "tl_params",
                iconStyle: SVGICON.Forms,
              },
              { title: "Targets", to: "tl_targets", iconStyle: SVGICON.Forms },
              { title: "Alerts", to: "tl_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;

  } else if (adminProfil === "admin") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "admin",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "admin_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "admin_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "admin_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "admin_retraits",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Psp", to: "admin_psp" },
          { title: "Platforme", to: "admin_neobank" },
          { title: "Banque client", to: "admin_bankclient" },
          { title: "Brand", to: "admin_Brand" },
          { title: "Desk", to: "admin_desk" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "admin" },
          {
            title: "Statistiques",
            to: "admin_desk_details",
            iconStyle: SVGICON.Charts,
          },
          { title: "Contrats", to: "admin_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "admin_calls", iconStyle: SVGICON.CallIcon },
          {
            title: "Retraits",
            to: "admin_retraits",
            iconStyle: SVGICON.Retraits,
          },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "admin_params",
                iconStyle: SVGICON.Forms,
                id: 0,
              },
              {
                title: "Targets",
                to: "admin_targets",
                iconStyle: SVGICON.Forms,
                id: 1,
              },
              { title: "Alerts", to: "admin_alerts", id: 2 },
            ],
          },
        ],
      },

      // {
      //   title: "Configurations_Desk",
      //   // iconStyle: SVGICON.Setting,
      //   content: [
      //     { title: "Utilisateurs", to: "admin_params" },
      //     { title: "Targets", to: "admin_targets" },
      //     { title: "Alerts", to: "admin_alerts" },
      //     // { title: "Psp", to: "admin_psp" },
      //     // { title: "Platforme", to: "admin_neobank" },
      //     // { title: "Banque client", to: "admin_bankclient" },
      //     // { title: "Brand", to: "admin_Brand" },
      //     // { title: "Desk", to: "admin_desk" },
      //   ],
      // },
    ];
  } else if (adminProfil === "boss_supp") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "cs",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "cs_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "cs_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "cs_calls",
      },
      {
        title: "Docs",
        iconStyle: SVGICON.Trello,
        to: "document",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Psp", to: "cs_psp" },
          { title: "Platforme", to: "cs_neobank" },
          { title: "Banque client", to: "cs_bankclient" },
          { title: "Brand", to: "cs_Brand" },
          { title: "Desk", to: "cs_desk" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Dashboard", to: "cs", iconStyle: SVGICON.Charts },
          { title: "Statistiques", to: "cs_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "cs_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "cs_calls", iconStyle: SVGICON.CallIcon },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "cs_params" },
              // { title: "Targets", to: "cs_targets" },
              { title: "Alerts", to: "cs_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "sell") {

    let menuItems = [{
      title: "Dashboard",
      iconStyle: SVGICON.Home,
      to: "sell",
    },
    {
      title: "Contrats",
      iconStyle: SVGICON.Forms,
      to: "sell_tab",
    },
    {
      title: "Documents",
      iconStyle: SVGICON.Trello,
      to: "document",
    },
    {
      title: "Performance",
      iconStyle: SVGICON.CheckRight,
      to: "m_performance_monthly_agent",

      // to: "m_performance",
    },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "agt_supp") {

    let menuItems = [{
      title: "Dashboard",
      iconStyle: SVGICON.Home,
      to: "supp",
    },
    {
      title: "Contrats",
      iconStyle: SVGICON.Forms,
      to: "supp_tab",
    },
    {
      title: "Docs",
      iconStyle: SVGICON.Trello,
      to: "document",
    }
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "mng_supp") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "ms",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "ms_stats",
      },
      {
        title: "Docs",
        iconStyle: SVGICON.Trello,
        to: "document",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "ms_tab",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "ms_params", iconStyle: SVGICON.Forms },
          // { title: "Targets", to: "ms_targets",iconStyle: SVGICON.Forms },
          { title: "Alerts", to: "ms_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Dashboard", to: "ms", iconStyle: SVGICON.Home },
          { title: "Statistiques", to: "ms_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "ms_tab", iconStyle: SVGICON.Forms },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "ms_params",
                iconStyle: SVGICON.Forms,
              },
              // { title: "Targets", to: "ms_targets",iconStyle: SVGICON.Forms },
              { title: "Alerts", to: "ms_alerts" },
            ],
          },
        ],
      },
    ];
  } else if (adminProfil === "ass_mng") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "s",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "s_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "s_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "s_calls",
      },
      {
        title: "Retraits",
        iconStyle: SVGICON.Retraits,
        to: "s_retraits",
      },
      {
        title: "Want To Refund",
        iconStyle: SVGICON.Refund,
        to: "s_refund",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          { title: "Utilisateurs", to: "s_params" },
          { title: "Targets", to: "s_targets" },
          { title: "Alerts", to: "s_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Statistiques", to: "s_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "s_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "s_calls", iconStyle: SVGICON.CallIcon },
          { title: "Retraits", to: "s_retraits", iconStyle: SVGICON.Retraits },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "s_params" },
              { title: "Targets", to: "s_targets" },
              { title: "Alerts", to: "s_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents" || item.title !== "Refund");
    }

    return menuItems;
  }
};

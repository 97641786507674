// DropCall.js
import React, { useState, useEffect, useRef } from "react";
import avatar from "../../../images/avataaars (1).svg";
import { SVGICON } from "../../../jsx/constant/theme";

const Timer = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formatTime = (seconds) => {
    const mins = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${mins}:${secs}`;
  };

  return <div>{formatTime(time)}</div>;
};

const DropCall = ({ data, handleClose }) => {
  const [isEditingNote, setIsEditingNote] = useState(false); // Added state
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [messageSend, setMessageSend] = useState(false);
  const [showActualIP, setShowActualIP] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const textareaRef = useRef(null);
  const [buttonActionsSel, setButtonActionsSel] = useState(true);

  useEffect(() => {
    if (isEditingNote && textareaRef.current) {
      textareaRef.current.focus(); // Focus textarea when editing
    }
  }, [isEditingNote]);

  const toggleShowActualIP = () => {
    setShowActualIP(false);
    setShowHistory(true);
    setIsEditingNote(false);
    setMessageSend(false);
    setShowMessageSent(false);
    setButtonActionsSel(false);
  };

  const handleSend = () => {
    // Perform send action here (e.g., save the note)
    setShowActualIP(true);
    setMessageSend(true);
    setShowMessageSent(true);
    setTimeout(() => {
      setShowMessageSent(false);
      setIsEditingNote(false);
      setNoteText("");
    }, 1000);
  };

  const handleSpeak = () => {
    if (messageSend) {
      const synth = window.speechSynthesis;
      const utterThis = new SpeechSynthesisUtterance(`biiiip biiiiip bip!`);
      synth.speak(utterThis);
      setMessageSend(false);
      handleClose();
    } else {
      setIsEditingNote(true);
    }
  };

  const [callHistory, setCallHistory] = useState([
    {
      type: "create",
      old: "",
      new: "New lead",
      date: "13/12/12",
      time: "11:00",
      note: "",
    },
    {
      type: "status change",
      old: "En cours",
      new: "Terminé",
      date: "12/12/12",
      time: "10:15",
      note: "",
    },
    {
      type: "status change",
      old: "En cours",
      new: "Terminé",
      date: "12/12/12",
      time: "10:15",
      note: "",
    },
  ]);

  const showButtonAction = () => {
    setButtonActionsSel(true);
    setShowActualIP(true);
    setIsEditingNote(false);
    setMessageSend(false);
    setShowMessageSent(false);
    setShowHistory(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-end rounded-top"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <button
          className="btn text-end p-1 me-3 mt-1"
          onClick={() => {
            handleClose();
          }}
        >
          x
        </button>
      </div>
      {data &&
        [data]?.map((item) => (
          <div
            key={item.id}
            className="rounded-bottom d-flex flex-column justify-content-center"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <div className="bg-gradient-vert rounded text-white fw-bold m-2 p-1">
              <div
                className={`fs-16 ms-2 col-5 border border-white rounded-1 m-2 px-0 text-center actual-ip-container ${
                  showActualIP ? "show" : "hide"
                }`}
              >
                + {item.actual_client_ip}
              </div>
              <div className="d-flex align-items-center ms-2">
                <img
                  src={avatar}
                  alt=""
                  style={{ height: "40px", marginRight: "10px" }}
                  className={` actual-ip-container ${
                    showActualIP ? "show" : "hide"
                  }`}
                />
                <div className="fs-18 fw-bold">{item.full_name}</div>
              </div>
              <div
                className={`col-12 d-flex justify-content-between rounded fs-14 px-3 my-2 actual-ip-container ${
                  showActualIP ? "show" : "hide"
                }`}
              >
                <div className="d-flex ">
                  <div>
                    <img
                      src="https://flagcdn.com/16x12/us.png"
                      srcset="https://flagcdn.com/32x24/us.png 2x,
    https://flagcdn.com/48x36/us.png 3x"
                      width="16"
                      height="12"
                      alt="United States"
                    />
                  </div>
                  <div>+ {item.actual_client_ip}</div>
                </div>
                <Timer />
              </div>
              <div className="rounded bg-vertlight">
                <div className="rounded d-flex justify-content-center bg-white p-1">
                  <div
                    onClick={() => showButtonAction()}
                    className={`col-6 rounded text-center py-1 fs-16 fw-normal pointer ${
                      buttonActionsSel
                        ? " bg-gardient-vertlight text-white"
                        : "text-black"
                    }`}
                  >
                    Actions
                  </div>
                  <div
                    onClick={() => toggleShowActualIP()}
                    className={`col-6 rounded text-center text-dark py-1 fs-16 fw-normal pointer ${
                      !buttonActionsSel
                        ? " bg-gardient-vertlight text-white"
                        : ""
                    }`}
                  >
                    History
                  </div>
                </div>
                <div className="d-flex justify-content-around p-1">
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      {SVGICON.PhoneRedIcon}
                    </div>
                    <div className="text-dark fw-normal">transfert</div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      {SVGICON.PauseIcon}
                    </div>
                    <div className="text-dark fw-normal">hold</div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    onClick={() => setIsEditingNote(!isEditingNote)}
                  >
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      {SVGICON.EarmarkIcon}
                    </div>
                    <div className="text-dark fw-normal">note</div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      {SVGICON.BitagIcon}
                    </div>
                    <div className="text-dark fw-normal">tags</div>
                  </div>
                </div>
              </div>
            </div>
            {!isEditingNote ? (
              <>
                {showHistory ? (
                  <div className="show-history">
                    <ul>
                      {callHistory.map((action, index) => (
                        <li key={index} className="card-history">
                          <div>
                            Type: <div>{action.type}</div>
                          </div>
                          <div>
                            Ancien Statut: <div>{action.old}</div>
                          </div>
                          <div>
                            Nouveau Statut: <div>{action.new}</div>
                          </div>
                          <p className="fs-12 d-flex justify-content-end">
                            {action.date} {action.time}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="border m-2 p-2 bg-white rounded border-0">
                    <div className="d-flex justify-content-around rounded p-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.MicMuteIcon}
                        </div>
                        <div className="text-dark fw-normal">mute</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.Gridx3Icon}
                        </div>
                        <div className="text-dark fw-normal">keypad</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.RecordIcon}
                        </div>
                        <div className="text-dark fw-normal">rec.</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around rounded p-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.BiPLusIcon}
                        </div>
                        <div className="text-dark fw-normal">call</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.ShareIcon}
                        </div>
                        <div className="text-dark fw-normal">share</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          {SVGICON.tabletIcon}
                        </div>
                        <div className="text-dark fw-normal">mobile</div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : showMessageSent ? (
              <>
                <div className="message-sent-container">
                  <div className="message-sent">Message envoyé</div>
                </div>
              </>
            ) : (
              <>
                <textarea
                  ref={textareaRef}
                  className="form-control col-11 m-auto textearea-call mb-2"
                  rows="3"
                  placeholder="Une note doit être écrite pour chaque appel."
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                />
                <button
                  className="btn border-0 col-10 m-auto border-black p-1 bg-vertlight text-black fw-bold"
                  onClick={handleSend}
                  disabled={!noteText.trim()}
                >
                  Envoyer
                </button>
              </>
            )}
            <div className="m-2 p-2 d-flex justify-content-around align-items-end">
              <div className="btn-icon-call-bottom pointer">
                {SVGICON.MicIcon}
              </div>
              <div className="btn-icon-call-red pointer">
                <div onClick={handleSpeak}>{SVGICON.TelephoneIcon}</div>
              </div>
              <div className="btn-icon-call-bottom pointer">
                {SVGICON.VolumeUpIcon}
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default DropCall;

const initRow = {
    created_at: { etat: 3 },
    desk: { etat: 3 },
    brand: { etat: 3 },
    cust: { etat: 3 },
    amount: { etat: 3 },
    agent: { etat: 3 },
    alias: { etat: 3 },
    date_ech: { etat: 3 },
    support: { etat: 3 },
    psp: { etat: 3 },
    Plateforme: { etat: 3 },
    arrivee: { etat: 3 },
    bank: { etat: 3 },
    en_preuve: { etat: 3 },
    date_vir: { etat: 3 },
    encaisse: { etat: 3 },
    appel_pro: { etat: 3 },
    heure: { etat: 3 },
    reporter: { etat: 3 },
    annuler: { etat: 3 },
    encash_usd: { etat: 3 },
    customerbank: { etat: 3 },
    neobank: { etat: 3 },
    customer_id: { etat: 3 },
    seller_id: { etat: 3 },
    tlead_id: { etat: 3 },
    agt_supp_id: { etat: 3 },
    mng_supp_id: { etat: 3 },
    is_active: { etat: 3 },
    conversion: { etat: 3 },
    paymentdudate: { etat: 3 },
    end_contract: { etat: 3 },
    argent_dispo: { etat: 3 },
    preuve: { etat: 3 },
    date_encaisse: { etat: 3 },
    next_call: { etat: 3 },
    hours: { etat: 3 },
    is_report: { etat: 3 },
    is_canceled: { etat: 3 },
    pseudo_agt_supp: { etat: 3 },
    pseudo_seller: { etat: 3 },
    comments: { etat: 3 },
    comments_timestamp: { etat: 3 },
    cust_fname: { etat: 3 },
    cust_lname: { etat: 3 },
    alias_lname: { etat: 3 },
    alias_fname: { etat: 3 },
  };

  export default initRow;
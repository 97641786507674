import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";

const GraphAverageRetap = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      updateChart();
    }, 1000);
  }, [data]);

  const updateChart = () => {
    const seriesData = data.map(item => ({
      x: item.pseudo,
      y: Number(item.avg_days_between_contracts),
      fillColor: Number(item.avg_days_between_contracts) <= 7 ? '#00FF00' : Number(item.avg_days_between_contracts) <= 15 ? '#FFA500' : '#FF0000'
    }));

    seriesData.sort((a, b) => a.y - b.y);
    const categories = seriesData.map(item => item.x);
    // const maxStat = Math.max(...seriesData.map(item => item.y));
    // console.log(maxStat)

    // Générer des annotations qui couvrent les plages de valeurs en utilisant les indices précis
    const generateVerticalAnnotations = () => {
      let annotations = [];
  
      // Trouver l'index où les valeurs dépassent 7 et 15
      const indexFor7 = seriesData.findIndex(item => item.y > 7); 
      const indexFor15 = seriesData.findIndex(item => item.y > 15);
      const indexFor100 = seriesData.length - 1; // Le dernier élément du tableau pour maxStat
  
      // console.log("Index pour 7:", indexFor7);
      // console.log("Index pour 15:", indexFor15);
      // console.log("Index pour maxStat:", indexFor100);
  
      // Annotation pour la plage de 0 à 7
      if (indexFor7 >= 0) {
          annotations.push({
              x: categories[0],
              x2: categories[indexFor7],
              borderColor: "#00FF00",
              fillColor: "#00FF00",
              opacity: 0.3,
              label: {
                  text: "0-7",
                  position: "top",
                  style: {
                      fontWeight: "800",
                      color: "#000",
                  },
              },
          });
      }
  
      // Annotation pour la plage de 7 à 15
      if (indexFor7 >= 0 && indexFor15 > indexFor7) {
          annotations.push({
              x: categories[indexFor7],
              x2: categories[indexFor15],
              borderColor: "#FFA500",
              fillColor: "#FFA500",
              opacity: 0.3,
              label: {
                  text: "7-15",
                  position: "top",
                  style: {
                      fontWeight: "800",
                      color: "#000",
                  },
              },
          });
      }
  
      // Annotation pour la plage de >15 à maxStat
      if (indexFor15 >= 0 && indexFor100 > indexFor15) {
          annotations.push({
              x: categories[indexFor15],
              x2: categories[indexFor100],
              borderColor: "#FF0000",
              fillColor: "#FF0000",
              opacity: 0.3,
              label: {
                  text: ">15",
                  position: "top",
                  style: {
                      fontWeight: "800",
                      color: "#000",
                  },
              },
          });
      }
  
      return annotations;
  };
  

    const verticalAnnotations = generateVerticalAnnotations();

    setSeries([
      {
        name: "Moyenne en jour de retap par client",
        data: seriesData,
      },
    ]);

    setOptions({
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          colors: {
            ranges: [
              {
                from: 0,
                to: 6,
                color: '#00FF00'
              },
              {
                from: 7,
                to: 15,
                color: '#FFA500'
              },
              {
                from: 16,
                to: 5000,
                color: '#FF0000'
              }
            ]
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"],
        },
        formatter: function (val) {
          return `${val}`;
        },
      },
      xaxis: {
        categories: categories,
      },
      legend: {
        position: "right",
        offsetY: 20,
      },
      fill: {
        colors: seriesData.map(item => item.fillColor),
      },
      annotations: {
        xaxis: verticalAnnotations,
      },
    });
  };

  if (loading) {
    return (
      <div className="chargement-graph-agent">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <div id="chart" className="line-chart-style bar-chart h-100">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default GraphAverageRetap;

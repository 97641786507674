import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getAdminDetails, getAdminDesks, getToken, getPseudo, getAdminProfil } from "../../Redux/slices/admin.slice";
import Cookies from 'js-cookie';
import localforage from 'localforage';
import config from "../../../config/config"
import Spinner from 'react-bootstrap/Spinner';
import Loader from "../LoaderPacMan";

const PageRefund = () => {
  const AdminDetails = useSelector(getAdminDetails);
  const AdminDesks = useSelector(getAdminDesks);
  const AdminToken = useSelector(getToken);
  const PSEUDO = useSelector(getPseudo);
  const PROFIL = useSelector(getAdminProfil);
  const [openIframe, setOpenIframe] = useState({ open: false, url: "" });

  useEffect(() => {
    console.log("Ouverture des docs", AdminDetails);
    getTokenOauth();
  }, []);

  const getTokenOauth = async () => {
    Cookies.remove('profil', { path: '/', domain: config.baseFront });

    // ici il faudra peut etre faire la logique pour filtrer les cards
    // Cookies.remove('srcgoogle', { path: '/', domain: config.baseFront });

    try {
      const timestamp = Date.now();

      const urlOriginTrello = config.urlTrello;
      if (urlOriginTrello === "") {
        return
      }

      // Première requête pour obtenir le token
      const resOauth = await axios.get(`${urlOriginTrello}/api/v1/oauth.json?_=${timestamp}`);
      // console.log("resOauth.data", resOauth.data);

      const accessToken = resOauth.data.access_token;

      // Deuxième requête pour se connecter
      const resLogin = await axios.post(
        `${urlOriginTrello}/api/v1/users/login.json?token=${accessToken}`,
        {
          email: AdminDetails.pseudo,
          password: AdminDetails.idusers,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("resLogin.data", resLogin.data);
      const { access_token, refresh_token, user } = resLogin.data;
      const { id, is_productivity_beats, initials, profile_picture_path, role_id,
        username, full_name, persist_card_divider_position, timezone,
        notify_count, last_activity_id, language, default_desktop_notification,
        is_list_notifications_enabled, is_card_notifications_enabled,
        is_card_members_notifications_enabled,
        is_card_labels_notifications_enabled,
        is_card_checklists_notifications_enabled,
        is_card_attachments_notifications_enabled,
        is_ldap, is_saml, is_intro_video_skipped,
        next_community_edition_popup_on, is_show_community_edition_popup,
        is_two_factor_authentication_enabled
      } = user;

      const filteredData = {
        access_token,
        refresh_token,
        user: {
          id,
          username,
          is_productivity_beats, initials, profile_picture_path, role_id,
          full_name, persist_card_divider_position, timezone, notify_count,
          last_activity_id, language, default_desktop_notification,
          is_list_notifications_enabled, is_card_notifications_enabled,
          is_card_members_notifications_enabled, is_card_labels_notifications_enabled,
          is_card_checklists_notifications_enabled, is_card_attachments_notifications_enabled,
          is_ldap, is_saml, is_intro_video_skipped, next_community_edition_popup_on,
          is_show_community_edition_popup, is_two_factor_authentication_enabled
        }
      };
      const encodedString = JSON.stringify(filteredData)
      // console.log("encodedString", encodedString);

      Cookies.set('auth', encodedString, { path: '/', domain: config.baseFront });
      Cookies.remove('push_tokens', { path: '/', domain: config.baseFront });

      let links = {};
      if (resLogin.data.links) {
        console.log("resLogin.data.links", resLogin.data.links);
        try {
          links = JSON.parse(resLogin.data.links);
          await localforage.setItem("links", links.links);
          //  console.log("l'action du localforage est terminee", resLogin.data.links)
        } catch (e) {
          console.error("Erreur lors du parsing de 'auth_response.links' :", e);
        }
      }

      Cookies.set('profil', PROFIL, { domain: config.baseFront });

      const arrayToStore = [PSEUDO];
      const arrayString = JSON.stringify(arrayToStore);
      Cookies.set('srcgoogle', arrayString, { domain: config.baseFront });

      // recupere tous les boards de l'user
      const resBoards = await axios.get(`${urlOriginTrello}/api/v1/organizations/17.json?token=${resLogin.data.access_token}`);
      console.log("resBoards.data", resBoards.data);
      const boardsListing = resBoards.data.boards_listing;

      const largestBoard = boardsListing?.reduce((max, board) => {
        return parseInt(board.id) > parseInt(max.id) ? board : max;
      }, boardsListing[0]);
      console.log("Le board avec le plus grand board_id :", largestBoard);
      console.log("Le plus grand board_id :", largestBoard.id);

      if (Cookies.get('auth') !== undefined) {
        if (largestBoard === undefined) {
          console.log(1)
          setOpenIframe(prevState => ({
            url: `${urlOriginTrello}/#/boards`,
            // url: `${urlOriginTrello}/#/boards`, // pour afficher tous les boards cas des managers
            open: true
          }));
        } else {
          console.log(2)
          setOpenIframe(prevState => ({
            url: `${urlOriginTrello}/#/board/${largestBoard?.id}`,
            // url: `${urlOriginTrello}/#/boards`, // pour afficher tous les boards cas des managers
            open: true
          }));
        }
      }




    } catch (error) {
      console.error("Erreur lors des appels API :", error);
    }
  };

  return (
    <>
      {openIframe.open
        ?
        <iframe src={openIframe.url} width="100%" height="1000px"></iframe>
        :
        <Loader />
      }
    </>
  );
};

export default PageRefund;
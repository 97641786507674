// TrTable.js
import React from "react";
import objRowTable from "./objRowTable";
import arrowSort from "./arrowSort";

const columns = [
  { label: "", key: null, type: null }, // Colonne vide
  { label: "ID", key: objRowTable.id?.key, type: objRowTable.id?.type },
  { label: "MT Login", key: objRowTable.mtlogin?.key, type: objRowTable.mtlogin?.type },
  { label: "Full Name", key: objRowTable.full_name?.key, type: objRowTable.full_name?.type },
  { label: "Retention Agent Name", key: objRowTable.retentionagentname?.key, type: objRowTable.retentionagentname?.type },
  { label: "Is FTD", key: objRowTable.is_ftd?.key, type: objRowTable.is_ftd?.type },
  { label: "Country", key: objRowTable.country?.key, type: objRowTable.country?.type },
  { label: "Status", key: objRowTable.status?.key, type: objRowTable.status?.type },
  { label: "Pool", key: objRowTable.pool?.key, type: objRowTable.pool?.type },
  { label: "Created Date", key: objRowTable.created_date?.key, type: objRowTable.created_date?.type },
  { label: "Converted Date", key: objRowTable.converted_date?.key, type: objRowTable.converted_date?.type },
  { label: "FTD Date", key: objRowTable.ftd_date?.key, type: objRowTable.ftd_date?.type },
  { label: "Actual Client IP", key: objRowTable.actual_client_ip?.key, type: objRowTable.actual_client_ip?.type },
  { label: "Verified", key: objRowTable.verified?.key, type: objRowTable.verified?.type },
  { label: "Unique ID", key: objRowTable.unique_id?.key, type: objRowTable.unique_id?.type },
  { label: "Total Deposit Amount", key: objRowTable.totaldepositamount?.key, type: objRowTable.totaldepositamount?.type },
  { label: "Last Status Date", key: objRowTable.laststatusdate?.key, type: objRowTable.laststatusdate?.type },
  { label: "Total Withdrawal Amount", key: objRowTable.totalwithdrawalamount?.key, type: objRowTable.totalwithdrawalamount?.type },
  { label: "Last Call Date", key: objRowTable.lastcalldate?.key, type: objRowTable.lastcalldate?.type },
  { label: "First Call Date", key: objRowTable.firstcalldate?.key, type: objRowTable.firstcalldate?.type },
  { label: "City", key: objRowTable.city?.key, type: objRowTable.city?.type },
  { label: "State", key: objRowTable.state?.key, type: objRowTable.state?.type },
  { label: "Gender", key: objRowTable.gender?.key, type: objRowTable.gender?.type },
  { label: "Initial Martial Status", key: objRowTable.initial_martial_status?.key, type: objRowTable.initial_martial_status?.type },
  { label: "Initial Experience", key: objRowTable.initial_experience?.key, type: objRowTable.initial_experience?.type },
  { label: "Initial Expectations", key: objRowTable.initial_expectations?.key, type: objRowTable.initial_expectations?.type },
  { label: "Initial Bonus", key: objRowTable.initial_bonus?.key, type: objRowTable.initial_bonus?.type },
  { label: "Initial Experienced On", key: objRowTable.initial_experienced_on?.key, type: objRowTable.initial_experienced_on?.type },
  { label: "Initial Experience Earn", key: objRowTable.initial_experience_earn?.key, type: objRowTable.initial_experience_earn?.type },
  { label: "Initial How to Contact", key: objRowTable.initial_how_to_contact?.key, type: objRowTable.initial_how_to_contact?.type },
  { label: "MT Last Online", key: objRowTable.mt_last_online?.key, type: objRowTable.mt_last_online?.type },
  { label: "Atompix Last Online", key: objRowTable.atompix_last_online?.key, type: objRowTable.atompix_last_online?.type },
  { label: "Dialer ID", key: objRowTable.dialer_id?.key, type: objRowTable.dialer_id?.type },
  { label: "Dialer Type", key: objRowTable.dialer_type?.key, type: objRowTable.dialer_type?.type },
];

const HeaderCell = ({ label, keyParam, typeParam, stateRow, stateP }) => {
  if (!keyParam || !typeParam) {
    return (
      <th className="fs-14 fw-bold text-black">
        <div className="d-flex align-items-center justify-content-center"></div>
      </th>
    );
  }

  const stateKey = label.toLowerCase().replace(/ /g, "_");
  const sortState = stateP[stateKey]?.etat;

  return (
    <th
      className="fs-14 fw-bold text-black cursor-pointer"
      onClick={() => stateRow(keyParam, typeParam)}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div>{label}</div>
        <div className="ms-1">{arrowSort(sortState)}</div>
      </div>
    </th>
  );
};

const TrTable = ({ stateP, stateRow }) => {
  return (
    <tr className="titre-tableau text-center">
      {columns.map((column, index) => (
        <HeaderCell
          key={index}
          label={column.label}
          keyParam={column.key}
          typeParam={column.type}
          stateRow={stateRow}
          stateP={stateP}
        />
      ))}
    </tr>
  );
};

export default TrTable;
import React from 'react';
import styled from 'styled-components';

const Loader = () => {
    return (
        <div className="row align-items-center justify-content-center" style={{ height: "80vh" }}>
            <div style={{ width: 300 }}>
                <StyledWrapper>
                    <main id="container">
                        <div className="dots">
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                        </div>
                        <div className="dots2">
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                            <div className="dot2" />
                        </div>
                        <div className="circle" />
                    </main>
                </StyledWrapper>
            </div>
        </div>
    );
}

const StyledWrapper = styled.div`
  .circle {
    position: relative;
    left: -100px;
    width: 0;
    height: 0;
    border: 50px solid #FDD835;
    border-radius: 50%;
    border-right-color: transparent;
    animation: move 5s linear 0s infinite normal forwards;
  }

  .circle:before {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    width: 0;
    height: 0;
    border: 50px solid #FDD835;
    border-radius: 50%;
    border-right-color: transparent;
    animation: chomp1 .25s ease-in-out 0s infinite normal forwards;
  }

  .circle:after {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    width: 0;
    height: 0;
    border: 50px solid #FDD835;
    border-radius: 50%;
    border-right-color: transparent;
    animation: chomp2 .25s ease-in-out 0s infinite normal forwards;
  }

  .loader {
    position: relative;
    top: 50px;
    width: 300px;
    height: 0px;
    border-top: 10px dotted black;
    animation: loader_4013 5s ease-in-out 0s infinite normal forwards;
  }

  .dots {
    position: relative;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border-radius: 50%;
    background: #1E88E5;
    animation: dot1 5s linear 0s infinite none normal;
  }

  .dot:nth-child(1) {
    animation-delay: 0s
  }

  .dot:nth-child(2) {
    animation-delay: 0.25s
  }

  .dot:nth-child(3) {
    animation-delay: 0.5s
  }

  .dot:nth-child(4) {
    animation-delay: 0.75s
  }

  .dot:nth-child(5) {
    animation-delay: 1s
  }

  .dot:nth-child(6) {
    animation-delay: 1.25s
  }

  .dot:nth-child(7) {
    animation-delay: 1.5s
  }

  .dot:nth-child(8) {
    animation-delay: 1.75s
  }

  .dot:nth-child(9) {
    animation-delay: 1.9s
  }

  .dot:nth-child(10) {
    animation-delay: 2.1s
  }

  .dots2 {
    position: relative;
    top: 50px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot2 {
    position: relative;
    left: -10px;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border-radius: 50%;
    background: #F44336;
    opacity: 0;
    animation: dot2 5s linear 0s infinite none normal;
  }

  .dot2:nth-child(10) {
    animation-delay: 2.5s
  }

  .dot2:nth-child(9) {
    animation-delay: 2.75s
  }

  .dot2:nth-child(8) {
    animation-delay: 3.0s
  }

  .dot2:nth-child(7) {
    animation-delay: 3.25s
  }

  .dot2:nth-child(6) {
    animation-delay: 3.5s
  }

  .dot2:nth-child(5) {
    animation-delay: 3.75s
  }

  .dot2:nth-child(4) {
    animation-delay: 4.0s
  }

  .dot2:nth-child(3) {
    animation-delay: 4.25s
  }

  .dot2:nth-child(2) {
    animation-delay: 4.5s
  }

  .dot2:nth-child(1) {
    animation-delay: 4.6s
  }
  /* #F44336;, #1E88E5, #FDD835; */
  @keyframes chomp1 {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(45deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes chomp2 {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes move {
    0%, 100% {
      left: -100px;
    }

    0%, 48% {
      transform: rotateY(0deg);
    }

    50%, 100% {
      transform: rotateY(180deg);
    }

    50% {
      left: 100%;
    }
  }

  @keyframes loader_4013 {
    0%, 100% {
      border-top: 10px dotted #1E88E5;
    }

    0%, 48% {
      border-top: 10px dotted #1E88E5;
    }

    50%, 100% {
      border-top: 10px dotted #F44336;
    }

    50% {
      border-top: 10px dotted #F44336;
    }
  }

  @keyframes dot1 {
    0%,4% {
      background: #1E88E5;
      opacity: 1;
    }

    5%,94% {
      background: #F44336;
      opacity: 0;
    }

    95%,100% {
      background: #1E88E5;
      opacity: 1;
    }
  }

  @keyframes dot2 {
    0%,4% {
      background: #F44336;
      opacity: 1;
    }

    5%,94% {
      opacity: 0;
    }

    95%,100% {
      background: #F44336;
      opacity: 1;
    }
  }`;

export default Loader;

import React, { useState } from "react";

/// Link
import { Link } from "react-router-dom";
// import { useMenuList } from "./Menu";
import { useMenuList } from "../../../jsx/layouts/nav/Menu";
import Logoutbtn from "../../../jsx/layouts/nav/Logoutbtn";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDesks, getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts, setFilters } from "../../Redux/slices/contract.slice";
import axios from "axios";
import config from "../../../config/config";

const SideBar = () => {

  const adminDesks = useSelector(getAdminDesks);
  const adminDetails = useSelector(getAdminDetails);
  const id = adminDetails?.idusers;

  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(null);

  const dispatch = useDispatch();

  const menuItems = useMenuList();

  const desksObjects = [];
  const TilteObjects = [];

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const GetAllContrats = (desk) => {
    const obj = {
      filters: {
        seller_id: [id],
      },
      filters_date: {},
      sorts: {},
      limit: "",
    };
    dispatch(
      setFilters({
        filters: {
          seller_id: [id],
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    console.log(obj)
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        Header
      )
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        // setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // setLoaderStart(false);
      });
  };

  // Parcourir menuItems pour trouver les éléments avec le titre "Desks"
  menuItems?.forEach((item) => {
    if (item.title === "Desks") {
      desksObjects.push(item);
    }
  });

  menuItems?.forEach((item) => {
    if (item.title !== "Desks") {
      TilteObjects.push(item);
    }
  });

  return (
    <div className={`deznav border-right`}>
      <div className="deznav-scroll d-flex flex-column">
        {TilteObjects?.map((item, itemIndex) => {
          return (
            <div className="d-flex flex-column" key={itemIndex}>
              <Link
                className="d-flex align-items-center mx-4 my-2"
                to={item?.to}
                onClick={() => {
                  if (item?.title === "Contrats") {
                    GetAllContrats(adminDesks);
                  }
                  setActiveMenuItemIndex(
                    activeMenuItemIndex === itemIndex ? null : itemIndex
                  );


                }}
              >
                <div className="menu-icon border rounded-5 p-2 bg-light ">
                  {item?.iconStyle}
                </div>
                <div className="sidebar-title ms-3">{item?.title}</div>
              </Link>
            </div>
          );
        })}
        <div className="switch-btn mt-auto">
          <Logoutbtn />
        </div>
      </div>
    </div>
  );
};

export default SideBar;

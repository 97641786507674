import React from "react";

import ReactApexChart from "react-apexcharts";

class TableauConvertion extends React.Component {
  constructor(props) {
    // console.log(props)
    super(props);

    const horizontal = this.props.horizontal;

    this.state = {
      series: [
        {
          name: "jours",
          data: this.props.data,
        },
      ],
      options: {
        chart: {
          type: "bar",
          // height: 230,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            borderRadius: 3,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["var(--bs-orange)"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["black"],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          lines: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
        xaxis: {
          show: false,
          categories: this.props.arrCategories,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
      },
    };
  }

  
  componentDidUpdate(prevProps) {
    if (  prevProps.data !== this.props.data || 
          prevProps.arrCategories !== this.props.arrCategories   ) {
      this.setState({
        series: [
          {
            name: "jours",
            data: this.props.data,
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props.arrCategories,
          },
        },
      });
    }
  }

  render() {
    return (
      <div
        id="chart"
        className="line-chart-style bar-chart h-100 d-flex align-items-center justify-content-center"
      >
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height='100%'
          style={{ width: '100%' }} 
        />
      </div>
    );
  }
}
export default TableauConvertion;

import React from 'react';
import { Badge } from 'react-bootstrap';
import CountUp from 'react-countup';

const DeskDetailBrutNet = ({ isLoaded, previousValue, currentValue, dataBrutNetPrev, dataRetraitSheet, formatNumber }) => {
  return (
    <div className="crm-cart-data d-flex">
      <div className="col">
        <div>
          <h5 className="d-block mb-1 text-black">Brut Prev</h5>
          {isLoaded && (
            <span
              style={{
                backgroundColor: "transparent",
                fontSize: "1.3rem",
                color: "black",
                fontWeight: "700",
              }}
            >
              <CountUp
                start={previousValue} // Utiliser l'ancienne valeur
                end={currentValue} // Utiliser la nouvelle valeur
                duration={3.5} // Ajuster la durée si nécessaire
                separator=" " // Nombre de décimales si nécessaire
              />
              $
            </span>
          )}
        </div>
        <div title="update du net Prev , il ne s'agit plus que des contrats encaisses, moins les retraits effectues">
          <h5 className="d-block mb-1 text-black">Net Prev</h5>
          <span
            style={{
              backgroundColor: "transparent",
              fontSize: "1.3rem",
              color: "black",
              fontWeight: "700",
            }}
          >
            {dataBrutNetPrev &&
              dataRetraitSheet &&
              formatNumber(
                Number(
                  dataBrutNetPrev?.brutNetPrev.brut_previsionnel
                ) +
                  Number(dataRetraitSheet[0].total_amount || 0) ||
                  0
              )}
            $
          </span>
        </div>
      </div>
      <div className="col">
        <h5 className="d-block mb-1 text-black">Net</h5>
        <span
          style={{
            backgroundColor: "transparent",
            fontSize: "1.3rem",
            color: "black",
            fontWeight: "700",
          }}
        >
          {dataBrutNetPrev &&
            dataRetraitSheet &&
            formatNumber(
              Number(
                dataBrutNetPrev?.brutNetPrev?.net_previsionnel ||
                  0
              ) + Number(dataRetraitSheet[0].total_amount || 0) ||
                0
            )}
          $
          <Badge bg="warning">
            {Math.round(
              ((Number(
                dataBrutNetPrev?.brutNetPrev?.net_previsionnel ||
                  0
              ) +
                Number(
                  dataRetraitSheet?.[0]?.total_amount || 0
                )) *
                100) /
                (dataBrutNetPrev?.brutNetPrev
                  ?.brut_previsionnel || 0) || 0
            )}
            %
          </Badge>
        </span>
      </div>
    </div>
  );
};

export default DeskDetailBrutNet;
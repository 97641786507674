import { SVGICON } from "../../../../jsx/constant/theme";

const BiXSquareIcon2 = SVGICON.BiXSquareIcon2;
const BiXSquareIcon1 = SVGICON.BiXSquareIcon1;

export const optionsSatutsDispo = [
    {
      value: "argent_dispo_check",
      label: "argent dispo_oui",
      checked: "1",
      Tofetch: "argent_dispo",
      rep: "oui",
      svg: ({BiXSquareIcon2}),
    },
    {
      value: "argent_dispo",
      label: "argent dispo_non",
      checked: "0",
      Tofetch: "argent_dispo",
      rep: "non",
      svg: ({BiXSquareIcon1}),
    },
];

export const optionsSatutsPreuve = [
    {
      value: "preuve_check",
      label: "preuve_oui",
      checked: "1",
      Tofetch: "preuve",
      rep: "oui",
      svg: ({BiXSquareIcon2}),
    },
    {
      value: "preuve",
      label: "preuve_non",
      checked: "0",
      Tofetch: "preuve",
      rep: "non",
      svg: ({BiXSquareIcon1}),
    },
];

export const optionsSatutsEncaisse = [
    {
      value: "encaisse_check",
      label: "encaisse_oui",
      checked: "1",
      Tofetch: "encaisse",
      rep: "oui",
      svg: ({BiXSquareIcon2}),
    },
    {
      value: "encaisse",
      label: "encaisse_non",
      checked: "0",
      Tofetch: "encaisse",
      rep: "non",
      svg: ({BiXSquareIcon1}),
    },
];

export const optionsSatutsReport = [
    {
      value: "is_report_check",
      label: "is report_oui",
      checked: "1",
      Tofetch: "is_report",
      rep: "oui",
      svg: ({BiXSquareIcon2}),
    },
    {
      value: "is_report",
      label: "is report_non",
      checked: "0",
      Tofetch: "is_report",
      rep: "non",
      svg: ({BiXSquareIcon1}),
    },
];

export const optionsSatutsAnnul = [
    {
      value: "is_canceled_check",
      label: "is canceled_oui",
      checked: "1",
      Tofetch: "is_canceled",
      rep: "oui",
      svg: ({BiXSquareIcon2}),
    },
    {
      value: "is_canceled",
      label: "is canceled_non",
      checked: "0",
      Tofetch: "is_canceled",
      rep: "non",
      svg: ({BiXSquareIcon1}),
    },
];

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import formatNumber from '../../../utils/formatNumber';

const GraphRetraitByBrand = ({ data }) => {

  const sortedData = data.sort((a, b) => Number(b.total_amount) - Number(a.total_amount));

  const brands = sortedData.map((item) => item.brand);
  const totalAmounts = sortedData.map((item) => Number(item.total_amount));
  const encaisseAmounts = sortedData.map((item) => Number(item.encash));

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    xaxis: {
      categories: brands,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#a66907', '#1fb505'], // Labels en noir
      },
      offsetX: 5, // Décale légèrement les labels à droite
      position: 'right', // Positionne les labels à droite
      formatter: function (val, opts) {
        const seriesIndex = opts.seriesIndex;
        return seriesIndex === 0 ? `${formatNumber(val)} €` : `${formatNumber(val)} $`;
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '90%',
        borderRadius: 0, // Pas d'arrondi pour maximiser la place
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex }) {
          return seriesIndex === 0 ? `${formatNumber(val)} €` : `${formatNumber(val)} $`;
        },
      },
    },
    series: [
      {
        name: 'Total Signe',
        data: totalAmounts,
      },
      {
        name: 'total Encaisse',
        data: encaisseAmounts,
      },
    ],
    colors: ['#fbc74e', "#4dfc4d"],
  };

  return (
    <div id="chart" className="col-12">
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default GraphRetraitByBrand;
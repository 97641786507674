export function toTitleCase(str) {
  if (!str) {
    return ""; // Retourne une chaîne vide si str est null, undefined ou une chaîne vide
  }

  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export function formatDate(dateString) {
  if (!dateString) return "";

  // Créer un objet Date en se basant sur le fuseau horaire de Jérusalem
  const date = new Date(
    new Date(dateString).toLocaleString("en-US", {
      timeZone: "Asia/Jerusalem",
    })
  );

  // Options pour formater la date
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    timeZone: "Europe/Paris", // Fuseau horaire de Jérusalem
  };

  // Convertir la date en string formatée en tenant compte du fuseau horaire de Jérusalem
  return date.toLocaleDateString("fr-FR", options);
}
export function getDateClass(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString);
  const today = new Date();

  // Comparer les jours (ignorer l'heure)
  const isBeforeToday = date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);

  return isBeforeToday ? "bg-green-table" : "bg-red-table";
}
export function transformDate(dateString) {
  // Vérifie que la chaîne de date est bien au format "MM-DD"
  if (typeof dateString !== "string" || dateString.length !== 5) {
    return dateString; // Retourne la chaîne telle quelle si elle ne correspond pas au format attendu
  }
  // Sépare les parties mois et jour
  const [month, day] = dateString.split("-");
  // Inverse les positions et retourne le nouveau format "DD-MM"
  return `${day}-${month}`;
}
export function handleDateChange(dates, setShowBtnFilter, setStartDate, setEndDate, setObjToFetch) {
    setShowBtnFilter(true);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const formatDateToYYYYMMDD = (date) => {
      if (!date) return null;
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
  
    let arrayToSend;
    if (start && end) {
      arrayToSend = [formatDateToYYYYMMDD(start), formatDateToYYYYMMDD(end)];
    } else if (start) {
      arrayToSend = [formatDateToYYYYMMDD(start)];
    }
  
    // Update the filters in your state or object to fetch data
    setObjToFetch((prevState) => ({
      ...prevState,
      filters_date: {
        created_at: arrayToSend,
      },
    }));
  }
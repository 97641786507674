import React from 'react';
import { Card, Badge } from 'react-bootstrap';

const DeskDetailCard = ({ dataPrevVsCurrentMonthByAgentTotal, formatNumber }) => {
  return (
    <>
      <Card className="col h-100 mx-2 p-0">
        <Card.Header>
          <h5 className="fw-bold">Mois en cours</h5>
        </Card.Header>
        <div className="d-flex">
          <div className="col-6 ">
            <div className="d-flex align-items-center p-2 ">
              <div className="fs-14 fw-bold text-black">
                Total signe :
              </div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.month_signed
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {dataPrevVsCurrentMonthByAgentTotal?.pourcent_signed}%
                </Badge>
              </div>
            </div>
            <div className="d-flex align-items-center p-2">
              <div className=" fw-bold text-black">Total en preuve :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.month_proof
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {dataPrevVsCurrentMonthByAgentTotal?.pourcent_proof}%
                </Badge>
              </div>
            </div>
            <div className="d-flex align-items-center p-2">
              <div className=" fw-bold text-black">Total encaisse :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.month_encashed
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {dataPrevVsCurrentMonthByAgentTotal?.pourcent_encashed}%
                </Badge>
              </div>
            </div>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex align-items-center py-3">
              <div className=" fw-bold text-black">TOTAL eur :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.month_total_curr
                )}
                €
              </div>
            </div>
            <div className="d-flex align-items-center py-3">
              <div className=" fw-bold text-black">TOTAL usd :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.month_total_usd_curr
                )}
                $
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="col h-100 mx-2 p-0">
        <Card.Header>
          <h5 className="fw-bold">Mois précédent</h5>
        </Card.Header>

        <div className="d-flex">
          <div className="col-6 ">
            <div className="d-flex align-items-center p-2">
              <div className="fs-14 fw-bold text-black">
                Total signé :
              </div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.prev_month_signed
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {
                    dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_signed
                  }
                  %
                </Badge>
              </div>
            </div>
            <div className="d-flex align-items-center p-2">
              <div className=" fw-bold text-black">Total en preuve :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.prev_month_proof
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {
                    dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_proof
                  }
                  %
                </Badge>
              </div>
            </div>
            <div className="d-flex align-items-center p-2">
              <div className=" fw-bold text-black">Total encaisse :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.prev_month_encashed
                )}
                €{" "}
                <Badge bg="warning" className="ms-2">
                  {
                    dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_encashed
                  }
                  %
                </Badge>
              </div>
            </div>
          </div>
          <div className="col-6 mt-3">
            <div className="d-flex align-items-center py-3">
              <div className=" fw-bold text-black">TOTAL eur :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.prev_month_total
                )}
                €
              </div>
            </div>
            <div className="d-flex align-items-center py-3">
              <div className=" fw-bold text-black">TOTAL usd :</div>
              <div className="d-flex align-items-center mx-2">
                {formatNumber(
                  dataPrevVsCurrentMonthByAgentTotal?.prev_month_total_usd
                )}
                $
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default DeskDetailCard;
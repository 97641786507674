import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import ShowDetailsLead from "./components/ShowDetailsLead";
import NotesLead from "./components/NotesLead";
import BankDetailLead from "./components/BankDetailLead";
import CallsLead from "./components/CallsLead";
import DocumentsLead from "./components/DocumentsLead";
import EmailHistoryLead from "./components/EmailHistoryLead";
import History from "./components/History";
import ReassignLogsLead from "./components/ReassignLogsLead";
import TradingAccountsLead from "./components/TrandingAccountsLead";
import TransactionsLead from "./components/TransactionsLead";
import WithDrawalRequestLead from "./components/WithdrawalRequestLead";
import Avatar from "../../../images/avataaars (2).svg";

const UpdateLead = ({ lead }) => {
  const [selectedSection, setSelectedSection] = useState("lead"); // Section 5 initialement sélectionnée

  const componentMap = {
    lead: ShowDetailsLead,
    Notes: NotesLead,
    BankDetails: BankDetailLead,
    EmailHistory: EmailHistoryLead,
    TradingAccounts: TradingAccountsLead,
    Transactions: TransactionsLead,
    Calls: CallsLead,
    History: History,
    Documents: DocumentsLead,
    ReassignLogs: ReassignLogsLead,
    WithdrawalRequest: WithDrawalRequestLead,
  };

  if (!lead) {
    return <div>Aucun lead sélectionné.</div>;
  }

  const toggleSection = (sectionNumber) => {
    setSelectedSection((prev) =>
      prev === sectionNumber ? null : sectionNumber
    );
  };

  const SelectedComponent = componentMap[selectedSection] || null;
  const sections = Object.keys(componentMap).filter(section => section !== "lead");

  return (
    <>
      <Offcanvas.Header className="center-flex p-1">
        <div
          className={`col-2 bg-light rounded-circle-lead center-flex pointer profil-lead grid-item-lead ${
            selectedSection === "lead" ? "selected-lead" : ""
          }`}
          onClick={() => toggleSection("lead")}
        >
          <img
            src={Avatar}
            alt=""
            style={{ height: "80px", marginBottom: "10px" }}
          />
        </div>
        <div className="col-10">
          <div className="grid-container-up-lead pointer mt-3">
            {sections?.map((section) => {
              return (
                <div
                  key={section}
                  className={`grid-item-lead center-flex ${
                    selectedSection === section ? "selected-lead" : ""
                  }`}
                  onClick={() => toggleSection(section)}
                >
                  {section}
                </div>
              );
            })}
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex mt-5">
        {SelectedComponent && <SelectedComponent />}
      </Offcanvas.Body>
    </>
  );
};

export default UpdateLead;

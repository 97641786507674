import React, { useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useParams } from "react-router-dom";
import { setPopup } from "../../Redux/slices/actions.slice";
import * as yup from "yup";

const DropCreateRetrait = ({ data, onHide }) => {
  const [brandClient, setBrandClient] = useState("");
  const [amountClient, setAmountClient] = useState("");
  const [error, setError] = useState(false);
  const [catchErrors, setCatchErrors] = useState([]);
  const [loaderButton, setLoaderButton] = useState(false);

  const [errorVirgule, setErrorVirgule] = useState(false);

  const AdminDetails = useSelector(getAdminDetails);
  const brandToAdmin = AdminDetails?.brand;
  const IdToAdmin = AdminDetails?.idusers;
  const deskToAdmin = AdminDetails?.desk;

  const dispatch = useDispatch();

  let DesksAdmin = useParams().desk;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const contractSchema = yup.object().shape({
    amount: yup
      .number()
      .test(
        "not-negative-zero",
        "Amount cannot be -0",
        (value) => value !== -0
      ),
    brand: yup.string().min(2).max(36),
    is_active: yup.boolean().default(true),
  });

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const optionsBrands = brandToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));

  const CreateRetrait = async () => {
    setLoaderButton(true);
    let obj = {
      amount: -amountClient,
      desk: DesksAdmin ? DesksAdmin : deskToAdmin[0],
      brand: brandClient,
      user_id: IdToAdmin,
      is_active: 1,
    };
    try {
      await contractSchema.validate(obj, { abortEarly: false });

      axios
        .put(
          `${config.apiAdmin}/sadmin/contracts/create-retrait-and-customer`,
          obj,
          Header
        )
        .then((response) => {
          setLoaderButton(false);
          SendPopup({
            message: "confirm",
            info: "create-retrait-and-customer.",
            isActive: true,
          });
          onHide();
          setError(false);
        })
        .catch((error) => {
          setLoaderButton(false);
          SendPopup({
            message: "error",
            info: "create-retrait-and-customer.",
            isActive: true,
          });
          setError(true);
          console.error("There was an error!", error);
        });
    } catch (validationError) {
      setLoaderButton(false);
      console.log(validationError.errors)
      setCatchErrors(validationError.errors);
      SendPopup({
        message: "error",
        info: "Validation failed.",
        isActive: true,
      });
      setError(true);
    }
  };

  return (
    <div>
      <div
        style={{ textDecoration: "underline" }}
        className="mb-3 fw-bold mt-1 text-black"
      >
        Informations Client
      </div>
      {catchErrors.length > 0 && (
        <div>
          <ul
            style={{ listStyleType: "disc", paddingLeft: "20px" }}
            className="text-danger"
          >
            <li className="fw-bold">champs a remplir :</li>
            {catchErrors.includes(
              'amount must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
            ) ||
              (catchErrors.includes("Amount cannot be -0") && <li>montant</li>)}
            {catchErrors.includes("brand must be at least 2 characters") && (
              <li>brand</li>
            )}
          </ul>
        </div>
      )}
      <div className="m-2">Retrait pour le desk {DesksAdmin}.</div>
      <div className="p-1 grid-container text-black">
        {/* <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Prénom</div>
          <input
            type="text"
            name="firstName"
            className="form-control"
            placeholder=""
            required
            onChange={(e) => setPrenomClient(e.target.value)}
          />
        </div>
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Nom</div>
          <input
            type="text"
            name="firstName"
            className="form-control"
            placeholder=""
            required
            onChange={(e) => setNomClient(e.target.value)}
          />
        </div>
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Customer ID</div>
          <input
            type="text"
            name="Customer ID"
            className="form-control"
            placeholder=""
            required
            onChange={(e) => setCustomer_id(e.target.value)}
          />
        </div> */}
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Brand</div>
          <ReactSelect
            options={optionsBrands}
            className="basic-single"
            classNamePrefix="select"
            name="brand"
            placeholder=""
            onChange={(selectedOption) =>
              setBrandClient(selectedOption ? selectedOption.value : null)
            }
            isClearable={true}
          />
        </div>
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Montant $</div>
          <input
            type="number"
            className="form-control"
            placeholder=""
            required
            onChange={(e) => {
              const value = e.target.value;
              if (value.includes(",") || value.includes(".")) {
                setErrorVirgule(true);
              } else {
                setErrorVirgule(false);
              }
              setAmountClient(value);
            }}
          />
          {errorVirgule && (
            <div className="text-danger fs-16">uniquement un nombre</div>
          )}
        </div>
      </div>
      <div className="p-1">
        {error && (
          <div className="text-red fs-16 m-2">Une erreur est survenue</div>
        )}
        <button
          disabled={loaderButton}
          className="border btn btn-green col-12 text-black h-50 me-2"
          onClick={() => CreateRetrait()}
        >
          Valider
        </button>
      </div>
    </div>
  );
};

export default DropCreateRetrait;

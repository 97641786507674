import React, { useState, useEffect } from "react";
import ava1 from "../../../../images/avataaars (1).svg";
import UpdateAdminByBossSupp from "../../../Components/Modals/UpdateAdminByBossSupp";
import AddAdminByBossSupp from "../../../Components/Modals/AddAdminByBossSupp";
import axios from "axios";
import config from "../../../../config/config";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdminDetails,
  getModalUpdated,
  setModalUpdated,
} from "../../../Redux/slices/admin.slice";
import { setInterdit } from "../../../Redux/slices/actions.slice";
import useHeader from "../../../../utils/useHeader";

const ListSupport = () => {
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showAddAdminSupp, setShowAddAdminSupp] = useState(false);
  const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
  const [allUsersBySadmin, setAllUsersBySadmin] = useState([]);

  const modalUpdated = useSelector(getModalUpdated);
  const adminDetails = useSelector(getAdminDetails);
  const rangAdmin = adminDetails?.rang;
  const Desk = adminDetails?.desk;
  const { desk } = useParams();

  const dispatch = useDispatch();

  const Header = useHeader();

  const fetchUsers = () => {
    let obj = {
      desk: desk || Desk.join(", "),
      categories: "SUPPORT",
      profil: "",
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/Users_by_desk_categories`,
        obj,
        Header
      )
      .then((response) => {
        dispatch(setModalUpdated(false));
        setAllUsersBySadmin(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalUpdated) {
      fetchUsers();
      handleCardClick(selectedMemberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalUpdated]);

  const handleCardClick = (idusers) => {
    setSelectedMemberId(idusers);
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/getUserByPseudo`,
        { pseudo: idusers?.pseudo },
        Header
      )
      .then((response) => {
        setSelectedMemberId(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const ShowModalAddAdmin = () => {
    setShowAddAdminSupp(true); // Mettre à jour l'état pour afficher le modal
  };

  const closeModalSupp = () => {
    setShowAddAdminSupp(false); // Mettre à jour l'état pour cacher le modal
  };

  const ShowModalUpdateAdmin = () => {
    setShowUpdateAdmin(true);
  };

  const closeModalUpdate = () => {
    dispatch(setModalUpdated(true));
    setShowUpdateAdmin(false); // Mettre à jour l'état pour cacher le modal
  };

  return (
    <div className="d-flex">
      <div
        className="w-50 ps-3 pt-3 col-6"
        style={{ borderRight: "1px #cdcdcd solid" }}
      >
        <div
          className="border btn text-black fw-bold fs-14 py-1 mb-2"
          style={{ backgroundColor: "#25e87f" }}
          onClick={() => ShowModalAddAdmin()}
        >
          <span className="pe-2 fs-4">+</span>Nouvelle utilisateur (SUPPORT)
        </div>
        <div style={{ overflowY: "auto", maxHeight: "700px" }}>
          {[...allUsersBySadmin]
            ?.reverse()
            ?.sort((a, b) => a.pseudo.localeCompare(b.pseudo))
            ?.map((member) => {
              return (
                <div
                  key={member?.idusers}
                  className="col-8 m-2 d-flex align-items-center card-admin-params ms-0"
                  style={{
                    border:
                      selectedMemberId?.id === member?.id
                        ? "1px solid #25e87f"
                        : "1px solid #e6e6e6",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleCardClick(member)}
                >
                  <div className="col-2 text-center">
                    <img
                      src={member?.avatar || ava1}
                      alt=""
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>
                  <div className="p-3">
                    <div className="text-black fs-16">{member?.pseudo}</div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`badge-list-admin badge-${member?.profil} text-white fs-12`}
                      >
                        {member?.profil}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="p-5 w-100">
        {selectedMemberId && (
          <div className="">
            <div className="d-flex align-items-center">
              <img src={ava1} alt="" style={{ height: "90px" }} />
              <div className="fs-20 text-black mt-1 ms-2">
                {selectedMemberId?.pseudo}
              </div>
              <div
                className={`badge-list-admin badge-${selectedMemberId?.profil} text-white fs-12 ms-4 mt-1`}
              >
                {selectedMemberId?.profil}
              </div>
              <div
                className="pointer"
                onClick={() => {
                  if (rangAdmin < selectedMemberId?.rang) {
                    ShowModalUpdateAdmin();
                  } else {
                    console.log("icic");
                    dispatch(setInterdit(true));
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  className="bi bi-pencil-fill ms-4"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                </svg>
              </div>
            </div>
            <div className="fw-bold text-black mt-3 ps-4">
              information utilisateur
            </div>
            <div className="list-info-perso mt-4">
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Pseudo</div>
                <div className="col-4">{selectedMemberId?.pseudo}</div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Profil</div>
                <div className="col-4">{selectedMemberId?.profil}</div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Code 2FA</div>
                <div className="col-4">
                  {selectedMemberId?.authenticator?.substring(0, 15)}...
                </div>
                <div>
                  <div
                    className="btn-copy"
                    style={{ cursor: "pointer" }}
                    data-tip="Copy"
                    onClick={() => {
                      navigator?.clipboard?.writeText(
                        selectedMemberId?.authenticator
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-copy"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Desks</div>
                <div className="col-4">
                  {JSON.stringify(selectedMemberId?.desk)}
                </div>
              </div>
              <div className="d-flex align-items-center ps-4">
                <div className="col-2">Brands</div>
                <div className="col-4">
                  {JSON.stringify(selectedMemberId?.brand)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showAddAdminSupp && (
        <AddAdminByBossSupp
          show={showAddAdminSupp}
          closeModal={closeModalSupp}
        />
      )}
      {showUpdateAdmin && (
        <UpdateAdminByBossSupp
          show={showUpdateAdmin}
          closeModal={closeModalUpdate}
          user={selectedMemberId}
        />
      )}
    </div>
  );
};

export default ListSupport;

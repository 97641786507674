import React, { useEffect, useState } from "react";
import CardsChefSupport from "./Graph/CardsChefSupport";
import ActiveProjects from "./Graph/ActiveProjects";
import { Table } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { getToken } from "../Redux/slices/admin.slice";
import { getSockets } from "../Redux/slices/notifSocket.slice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useHeaderRest from "../../utils/useHeader";
import axios from "axios";
import config from "../../config/config";
import { getAdminDesks } from "../Redux/slices/admin.slice";
import TableauGeneral from "../Manager/Graph/TableauGeneral";
import { Card } from "react-bootstrap";
import formatNumber from "../../utils/formatNumber";

const GET_PART_PSP = gql`
  query GetPartPsp {
    GetPartPsp {
      nombre_de_lignes
      part_encaisse_total
      pourcentage_in
      psp
      sum_amount
    }
  }
`;

const GET_REPORT_CONTRACT = gql`
  query GetReportContractByProfilDesk($desks: [String]) {
    GetReportContractByProfilDesk(desks: $desks) {
      total_amount
      total_amount_with_preuve
      total_amount_with_encaisse
      total_amount_reported_not_encashed
    }
  }
`;

const GET_LIST_RECAP_SUPPORT = gql`
  query GetListRecapSupport {
    GetListRecapSupport {
      idcontracts
      pseudo
      fname
      lname
      argent_dispo
      preuve
      encaisse
      is_report
      is_canceled
      paymentdudate
    }
  }
`;
const CONTRACT_TO_DO = gql`
  query BossSuppContractsToDo($desks: [String]) {
    BossSuppContractsToDo(desks: $desks) {
      total
      tot_without_psp
      tot_without_bank
      tot_without_rdv
      tot_report
    }
  }
`;

const PageDashboard = () => {
  const GET_TOKEN = useSelector(getToken);
  const GET_SOCKETS = useSelector(getSockets);
  const GET_ADMIN_DESKS = useSelector(getAdminDesks);
  const { desk } = useParams();
  const [goodDesk, setGoodDesk] = React.useState(
    desk ? [desk] : GET_ADMIN_DESKS
  );
  const [refreshKey, setRefreshKey] = React.useState(0);
  const useHeader = useHeaderRest();

  const [dataWeeklyTable, setDataWeeklyTable] = useState();

  useEffect(() => {
    setGoodDesk(desk ? [desk] : null);
    setRefreshKey((prevKey) => prevKey + 1); // Force le re-rendu du composant
  }, [desk]);

  const Header = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      },
    },
  };

  const { data: dataPartPsp, refetch: refetchPartPsp } = useQuery(
    GET_PART_PSP,
    Header
  );
  const { data: dataReportContract, refetch: refetchReportContract } = useQuery(
    GET_REPORT_CONTRACT,
    {
      context: {
        headers: {
          Authorization: `Bearer ${GET_TOKEN}`,
        },
      },
      variables: { desks: goodDesk },
    }
  );
  const { data: dataRecapBossSupp, refetch: refetchRecapBossSupp } = useQuery(
    GET_LIST_RECAP_SUPPORT,
    Header
  );
  const { data: dataContractTodo, refetch: refetchContractTodo } = useQuery(
    CONTRACT_TO_DO,
    {
      context: {
        headers: {
          Authorization: `Bearer ${GET_TOKEN}`,
        },
      },
      variables: { desks: goodDesk },
    }
  );

  const dataWeeklyTableByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_weekly_table_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataWeeklyTable(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("dataReportContract", dataReportContract);

  useEffect(() => {
    refetchPartPsp();
    refetchReportContract();
    refetchRecapBossSupp();
    refetchContractTodo();
    dataWeeklyTableByDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, desk, refreshKey]);

  return (
    <div className="row">
      <div className="col-9">
        <div className="">
          {dataReportContract?.GetReportContractByProfilDesk &&
            dataContractTodo?.BossSuppContractsToDo && (
              <CardsChefSupport
                dataReportContract={
                  dataReportContract?.GetReportContractByProfilDesk
                }
                dataContractTodo={dataContractTodo?.BossSuppContractsToDo}
              />
            )}
        </div>
          {dataWeeklyTable && (
            <TableauGeneral dataWeeklyData={dataWeeklyTable} />
          )}
      </div>
      <div className="col-3">
        <Card className="table-responsive h-100">
          <Table className="table h-100">
            <thead className="table-dark">
              <tr>
                <th>PSP</th>
                <th title={"Total Amount"}>Amount</th>
                <th>IN</th>
                <th title={"Nombres de transactions"}>Nbre</th>
                <th title={"Part sur le chiffre"}>Part</th>
              </tr>
            </thead>
            <tbody>
              {dataPartPsp?.GetPartPsp?.map((row, index) => (
                <tr key={index} className="text-end">
                  <td className="fw-bold" title={row.psp}>
                    {row.psp?.slice(0, 10)}
                  </td>
                  <td title={row.sum_amount}>{formatNumber(row?.sum_amount)}</td>
                  <td title={row.pourcentage_in}>{row.pourcentage_in}%</td>
                  <td title={row.nombre_de_lignes}>{row.nombre_de_lignes}</td>
                  <td title={row.part_encaisse_total}>
                    {row.part_encaisse_total}%
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
    </div>
  );
};

export default PageDashboard;

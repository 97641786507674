import React from 'react';

const DeskDetailPodium = ({ dataRatioAgent, formatNumber, FondPodiumOk, avatar, avatar1 }) => {
  return (
    <div
      className="card crm-cart"
      style={{
        backgroundImage: `url(${FondPodiumOk})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="d-flex text-white justify-content-around align-items-end mx-4 px-2 col-10"
        style={{ height: "65%" }}
      >
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            src={dataRatioAgent[1]?.avatar || avatar1}
            title={
              dataRatioAgent &&
              dataRatioAgent[1]?.pseudo +
                "\n " +
                formatNumber(dataRatioAgent[1]?.total_esperance || 0) +
                " €"
            }
            alt=""
            style={{
              height: "70px",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
              borderRadius: "50%",
              marginBottom: "10px",
            }}
          />
          <span
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
              fontFamily: "Bangers, cursive",
              letterSpacing: "1.5px",
              backgroundColor: "white",
              borderRadius: "50px",
              border: "1px solid grey",
            }}
          >
            {dataRatioAgent && dataRatioAgent[1]?.pseudo}
          </span>
        </div>

        <div className="mb-3">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={dataRatioAgent[0]?.avatar || avatar}
              alt=""
              style={{
                height: "70px",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
                marginBottom: "10px",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: "100%",
                left: "45%",
                transform: "translate(-50%, -50%)",
                color: "black",
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "center",
                padding: "0px 5px 0px 5px",
                fontFamily: "Bangers, cursive",
                letterSpacing: "1.5px",
                backgroundColor: "white",
                borderRadius: "50px",
                border: "1px solid grey",
              }}
            >
              {dataRatioAgent && dataRatioAgent[0]?.pseudo}
            </span>
          </div>
        </div>

        <div className="mb-3">
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={dataRatioAgent[2]?.avatar || avatar}
              alt=""
              style={{
                height: "70px",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
                marginBottom: "10px",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "black",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "bold",
                width: "100%",
                fontFamily: "Bangers, cursive",
                letterSpacing: "1.5px",
                backgroundColor: "white",
                borderRadius: "50px",
                border: "1px solid grey",
              }}
            >
              {dataRatioAgent && dataRatioAgent[2]?.pseudo}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeskDetailPodium;
// ContentTableLeads.js
import React from "react"; // Adjust the path as needed
import avatar from "../../../../images/avataaars (1).svg"; // Adjust the path as needed
import { SVGICON } from "../../../../jsx/constant/theme";

const ContentTableLeads = ({
  lead,
  handleShowUpdate,
  handleSelectRow,
  selectedLeads,
  handleShow,
  handleStatusChange,
}) => {
  return (
    <tr
      key={lead.idleads}
      style={{ cursor: "pointer" }}
      onClick={() => handleShowUpdate(lead)}
    >
      <td onClick={(e) => e.stopPropagation()}>
        <div
          className={`custom-checkbox-container ${
            selectedLeads.includes(lead.id) ? "checked" : ""
          }`}
          onClick={() => handleSelectRow(lead.id)}
        >
          <div
            className={`custom-checkbox ${
              selectedLeads.includes(lead.id) ? "checked" : ""
            }`}
          ></div>
        </div>
      </td>
      {/* <td>{lead.id}</td> */}
      <td onClick={(e) => e.stopPropagation()}>
        <div onClick={() => handleShow(lead)}>{SVGICON.PhoneTableIcon}</div>
      </td>
      <td>{lead.mtlogin}</td>
      <td>{lead.full_name}</td>
      <td>
        <div className="center-flex">
          <img src={avatar} alt="" style={{ height: "24px" }} />
          {lead.retentionagentname}
        </div>
      </td>
      <td>{lead.is_ftd ? "Yes" : "No"}</td>
      <td>{lead.country}</td>
      <td onClick={(e) => e.stopPropagation()}>
        <select
          value={lead.status}
          onChange={(e) => handleStatusChange(lead.id, e.target.value)}
          className="status-dropdown pointer"
        >
          <option value="Active">Active</option>
          <option value="Pending">Pending</option>
          <option value="Inactive">Inactive</option>
          <option value="Call again">Call again</option>
        </select>
      </td>
      <td>{lead.pool}</td>
      <td>{new Date(lead.created_date)?.toLocaleString() || "No date"}</td>
      <td>{new Date(lead.converted_date)?.toLocaleString() || "No date"}</td>
      <td>{new Date(lead.ftd_date)?.toLocaleString() || "No date"}</td>
      <td>{lead.actual_client_ip}</td>
      <td>{lead.verified}</td>
      <td>{lead.unique_id}</td>
      <td>{lead.totaldepositamount}</td>
      <td>{new Date(lead.laststatusdate).toLocaleString() || "No date"}</td>
      <td>{lead.totalwithdrawalamount}</td>
      <td>{lead.lastcalldate || "N/A"}</td>
      <td>{lead.firstcalldate || "N/A"}</td>
      <td>{lead.city}</td>
      <td>{lead.state}</td>
      <td>{lead.gender || "N/A"}</td>
      <td>{lead.initial_martial_status || "N/A"}</td>
      <td>{lead.initial_experience ? "Yes" : "No"}</td>
      <td>{lead.initial_expectations || "N/A"}</td>
      <td>{lead.initial_bonus || "N/A"}</td>
      <td>{lead.initial_experienced_on || "N/A"}</td>
      <td>{lead.initial_experience_earn ? "Yes" : "No"}</td>
      <td>{lead.initial_how_to_contact || "N/A"}</td>
      <td>{lead.mt_last_online}</td>
      <td>{lead.atompix_last_online || "N/A"}</td>
      <td>{lead.dialer_id}</td>
      <td>{lead.dialer_type || "N/A"}</td>
    </tr>
  );
};

export default ContentTableLeads;

import React, { useState } from "react";
import Targetperso from "./TargetsPerso";
import TargetsGeneral from "./TargetsGeneral";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useSelector } from "react-redux";

const Targets = () => {
  const [activeComponent, setActiveComponent] = useState("Targetperso");
  const [activeButton, setActiveButton] = useState("Targetperso");

  const detailAdmin = useSelector(getAdminDetails);

  // Vérifie si le profil de l'admin est 'manager' ou 'admin'
  const isManagerOrAdmin =
    detailAdmin.profil === "manager" || detailAdmin.profil === "admin";

  return (
    <div style={{ height: "700px" }}>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${
            activeButton === "Targetperso" ? "underline" : ""
          }`}
          onClick={() => {
            setActiveComponent("Targetperso");
            setActiveButton("Targetperso");
          }}
        >
          Targets personnel
        </div>

        {isManagerOrAdmin && (
          <div
            className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${
              activeButton === "TargetsGeneral" ? "underline" : ""
            }`}
            onClick={() => {
              setActiveComponent("TargetsGeneral");
              setActiveButton("TargetsGeneral");
            }}
          >
            Targets desk
          </div>
        )}
      </div>

      <div>
        {isManagerOrAdmin && activeComponent !== "TargetsGeneral" && (
          <Targetperso />
        )}
        {isManagerOrAdmin && activeComponent === "TargetsGeneral" && (
          <TargetsGeneral />
        )}
      </div>
    </div>
  );
};

export default Targets;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMenuList } from "../../../jsx/layouts/nav/Menu";
import Logoutbtn from "../../../jsx/layouts/nav/Logoutbtn";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import ReactSelect from "react-select";
import { setAllContracts, setFilters } from "../../Redux/slices/contract.slice";
import axios from "axios";
import config from "../../../config/config";
import { setDeskSidebar } from "../../Redux/slices/actions.slice";
import sifflet from "../../../son/sonfifa.wav";
import useHeader from "../../../utils/useHeader";
import { SVGICON } from "../../../jsx/constant/theme";

const SideBar = () => {
  const adminDesks = useSelector(getAdminDesks);

  const [state, setState] = useState({
    activeMenuItemIndex: 0,
    activeMenuItemIndexSub: 0,
    selectedLinkIndex: null,
    selectedLinkIndexSub: null,
    showDetails: false,
    goodIndexBot: null,
    showDetailsDesk: false,
    deskSelected: null,
    showDetailsDeskChildren: false,
  });

  const menuItems = useMenuList();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desksObjects = [];
  const TilteObjects = [];

  menuItems?.forEach((item) => {
    if (item.title === "Desks") {
      desksObjects.push(item);
    }
  });

  menuItems?.forEach((item) => {
    if (item.title !== "Desks") {
      TilteObjects.push(item);
    }
  });

  const desksUser = adminDesks?.map((desk) => ({
    value: desk,
    label: desk,
  }));

  const handleDesk = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      showDetailsDesk: true,
      deskSelected: selectedOption.value,
      goodIndexBot: null,
      selectedLinkIndex: null,
      activeMenuItemIndexSub: null,
    }));
    dispatch(setDeskSidebar(selectedOption.value));
    navigate(`/m_stats/${selectedOption.value}`);
  };

  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      showDetails: !prevState.showDetails,
    }));
  };

  const Header = useHeader();

  const GetAllContrats = (desk) => {
    const obj = {
      filters: {
        desk: desk,
        is_active: 1,
      },
      filters_date: {},
      sorts: {},
      limit: "",
    };
    dispatch(
      setFilters({
        filters: {
          desk: desk,
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    axios
      .post(`${config.apiAdmin}/sadmin/contracts/search_and_sort`, obj, Header)
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const desksItem = menuItems.find((item) => item.title === "Desks");

  const playWhistleSound = () => {
    const audio = new Audio(sifflet);
    audio.volume = 0.025;
    audio.play();
  };

  return (
    <div className={`deznav border-right`}>
      <div className="deznav-scroll d-flex flex-column">
        {TilteObjects?.map((item, itemIndex) => {
          if (item?.title === "Configurations" && adminDesks.length > 1) {
            return null;
          }
          if (
            item?.title === "Leads" &&
            ["proddub", "prod"].includes(process.env.REACT_APP_CONFIG)
          ) {
            return null;
          }
          return (
            <div className="d-flex flex-column" key={itemIndex}>
              <Link
                onClick={() => {
                  if (item?.title === "Contrats") {
                    GetAllContrats(adminDesks);
                  }
                  setState((prevState) => ({
                    ...prevState,
                    activeMenuItemIndex:
                      prevState.activeMenuItemIndex === itemIndex
                        ? null
                        : itemIndex,
                    showDetailsDesk: false,
                    showDetailsDeskChildren: false,
                    deskSelected: null,
                    goodIndexBot: null,
                    selectedLinkIndex: null,
                  }));
                }}
                to={item?.to}
                className={`d-flex align-items-center mx-4 my-2 btn-sidebar-title rounded-3 ${
                  itemIndex === state.activeMenuItemIndex
                    ? "bg-light border"
                    : ""
                }`}
              >
                <div className="menu-icon border rounded-5 p-2 bg-light">
                  {item?.iconStyle}
                </div>
                <div className="sidebar-title ms-3">{item?.title}</div>
              </Link>
              {item?.content && state.activeMenuItemIndex === itemIndex
                ? item?.content.map((subItem, subIndex) => {
                    const titlesToExclude = adminDesks.length >= 1 ? [] : [];
                    if (titlesToExclude.includes(subItem?.title)) {
                      return null;
                    }
                    return (
                      <Link
                        to={subItem?.to || "#"}
                        key={subIndex}
                        className={`sidebar-config my-1 py-1 col-6 ps-3 ${
                          state.selectedLinkIndex === subIndex ? "selected" : ""
                        }`}
                        onClick={() => {
                          if (
                            subItem?.title === "Journalière" ||
                            subItem?.title === "Mensuelle"
                          ) {
                            playWhistleSound();
                          }
                          setState((prevState) => ({
                            ...prevState,
                            selectedLinkIndex: subIndex,
                          }));
                        }}
                      >
                        <div>{subItem?.title}</div>
                      </Link>
                    );
                  })
                : null}
            </div>
          );
        })}
        {adminDesks?.length > 1 && (
          <div>
            <div className="fs-20 text-black py-2" onClick={handleClick}>
              <div className="d-flex align-items-center mx-4">
                <Link
                  to={"m"}
                  className="menu-icon border rounded-5 p-2 bg-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      showDetailsDesk: false,
                      showDetailsDeskChildren: false,
                      deskSelected: 0,
                      goodIndexBot: null,
                      activeMenuItemIndex: 0,
                    }));
                  }}
                >
                  {SVGICON.MainIcon}
                </Link>
                <div className="sidebar-title ms-3"></div>
                <ReactSelect
                  options={desksUser}
                  onChange={handleDesk}
                  className="fs-18"
                  placeholder="Desk"
                  value={state.deskSelected ? desksUser?.label : null}
                />
              </div>
            </div>
            {state.showDetailsDesk && (
              <div>
                {desksItem?.content?.map((contentItem, index) => {
                  return (
                    <div className="d-flex flex-column" key={index}>
                      <Link
                        to={
                          contentItem?.title === "Performance"
                            ? "#"
                            : contentItem?.to + "/" + state.deskSelected
                        }
                        key={index}
                        onClick={() => {
                          if (contentItem?.title === "Contrats") {
                            GetAllContrats(state.deskSelected);
                          }
                          setState((prevState) => ({
                            ...prevState,
                            activeMenuItemIndexSub:
                              prevState.activeMenuItemIndexSub === index
                                ? null
                                : index,
                            showDetailsDeskChildren: false,
                            goodIndexBot: null,
                            selectedLinkIndexSub: null,
                            selectedLinkIndex: null,
                          }));
                        }}
                        className={`d-flex align-items-center my-2 col-10 m-auto btn-sidebar-title rounded-3 ${
                          index === state.activeMenuItemIndexSub
                            ? "bg-light border"
                            : ""
                        }`}
                      >
                        <div className="menu-icon border rounded-5 p-2 bg-light">
                          {contentItem?.iconStyle}
                        </div>
                        <div className="sidebar-title-sub ms-3">
                          {contentItem.title}
                        </div>
                      </Link>
                      {contentItem?.content && state.activeMenuItemIndexSub === index
                        ? contentItem?.content.map((subItem, subIndex) => {
                            const titlesToExclude = adminDesks.length >= 1 ? [] : [];
                            if (titlesToExclude.includes(subItem?.title)) {
                              return null;
                            }
                            return (
                              <Link
                                to={subItem?.to + "/" + state.deskSelected || "#"}
                                key={subIndex}
                                className={`sidebar-config my-1 py-1 col-6 ps-3 ${
                                  state.selectedLinkIndexSub === subIndex ? "selected" : ""
                                }`}
                                onClick={() => {
                                  if (
                                    subItem?.title === "Journalière" ||
                                    subItem?.title === "Mensuelle"
                                  ) {
                                    playWhistleSound();
                                  }
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedLinkIndexSub: subIndex,
                                  }));
                                }}
                              >
                                <div>{subItem?.title}</div>
                              </Link>
                            );
                          })
                        : null}
                    </div>
                  );
                })}
                {desksItem?.children?.map((contentItemCh, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showDetailsDeskChildren:
                          !prevState.showDetailsDeskChildren,
                      }));
                    }}
                    className={`d-flex align-items-center my-2 col-10 m-auto btn-sidebar-title rounded-3}`}
                  >
                    <div className="menu-icon border rounded-5 p-2 bg-light">
                      {contentItemCh?.iconStyle}
                    </div>
                    <div className="sidebar-title-sub ms-3">
                      {contentItemCh?.title?.slice(0, 14)}
                    </div>
                    <div>{SVGICON.CarretDownIcon}</div>
                    <div></div>
                  </div>
                ))}
              </div>
            )}
            {state.showDetailsDeskChildren && (
              <div className="d-flex align-items-center">
                {desksItem?.children?.map((child, index) => (
                  <div key={index} className="col-10 m-auto">
                    {child.content?.map((contentItem, contentIndex) => (
                      <Link
                        key={contentIndex}
                        to={contentItem?.to + "/" + state.deskSelected || "#"}
                        className={`d-flex align-items-center btn-sidebar-title-hover rounded-3 p-2 ${
                          contentIndex === state.goodIndexBot ? "bg-light " : ""
                        }`}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            goodIndexBot: contentIndex,
                            selectedLinkIndex: null,
                            selectedLinkIndexSub: null,
                          }));
                        }}
                      >
                        <div
                          key={contentIndex}
                          className={`sidebar-config ms-5 `}
                        >
                          {contentItem?.title}
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="switch-btn mt-auto">
          <Logoutbtn />
        </div>
      </div>
    </div>
  );
};

export default SideBar;

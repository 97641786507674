import axios from 'axios';
import config from '../../../config/config';

export const DataSignedEncashedByDesk = (goodDesk, setDataSignedEncashed, setPreviousValueEncaisse, setCurrentValueEncaisse, setIsLoadedEncaisse, setPreviousValuePreuve, setCurrentValuePreuve, setIsLoadedPreuve, currentValueEncaisse, currentValuePreuve, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_signed_encashed_by_desk`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataSignedEncashed(response?.data);
      const newValueEncaisse = response.data.signed?.sum_encashed;
      const newValuePreuve = response.data.sum_proof;

      setPreviousValueEncaisse(currentValueEncaisse);
      setCurrentValueEncaisse(newValueEncaisse);
      setIsLoadedEncaisse(true);

      setPreviousValuePreuve(currentValuePreuve);
      setCurrentValuePreuve(newValuePreuve);
      setIsLoadedPreuve(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DataConversion = (goodDesk, setDataConversion, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_conversion_per_desk`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataConversion(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DataRatioAgent = (goodDesk, setDataRatioAgent, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_ration_agent`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataRatioAgent(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DataDailyContracts = (goodDesk, setDataDailyContracts, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_daily_contracts`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataDailyContracts(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DataMonthlyContracts = (goodDesk, setDataMonthlyContracts, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_monthly_contracts`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataMonthlyContracts(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const dataWeeklyTableByDesk = (goodDesk, setDataWeeklyTable, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/card_weekly_table_by_desk`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataWeeklyTable(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const targetByDesk = (goodDesk, setDataTargetByDesk, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/target_desk_by_desk`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataTargetByDesk(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const brutnetPrev = (goodDesk, setDataBrutNetPrev, setPreviousValue, setCurrentValue, setIsLoaded, currentValue, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/brut_net_prev`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataBrutNetPrev(response?.data?.data);
      const newValue = response.data.data.brutNetPrev.brut_previsionnel;
      setPreviousValue(currentValue);
      setCurrentValue(newValue);
      setIsLoaded(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const prevVsCurrentMonthByAgent = (goodDesk, setDataPrevVsCurrentMonthByAgent, setDataPrevVsCurrentMonthByAgentTotal, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/prev_vs_current_month_agent`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataPrevVsCurrentMonthByAgent(response?.data?.data);
      setDataPrevVsCurrentMonthByAgentTotal(response?.data?.totalData);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const retraitSheet = (goodDesk, setDataRetraitSheet, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/sadmin/whsheet/sum_retrait`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataRetraitSheet(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GraphEncashedMonthlyByDesk = (goodDesk, setDataGraphEncashed, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/graph_encashed_monthly_by_desk`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setDataGraphEncashed(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GraphMoyenneAgent = (goodDesk, setAverageByAgent, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/graph_average_by_agent`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setAverageByAgent(response?.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const GraphLastcontratByday = (goodDesk, setLastContractsByDay, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/graph_last_contrat_by_day`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setLastContractsByDay(response?.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const AverageRetap = (goodDesk, setAverageRetap, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/graph_average_retap`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setAverageRetap(response?.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getRetraitByBrand = (goodDesk, setRetraitByBrand, headerRest) => {
  axios
    .post(
      `${config.apiAdmin}/statsNode/manager/graph_retraits_by_brand`,
      { desk: goodDesk },
      headerRest
    )
    .then((response) => {
      setRetraitByBrand(response?.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
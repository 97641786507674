import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const AddLead = () => {
  const [crmId, setCrmId] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [leadToSend, setLeadToSend] = useState(null);

  const handleGenerateUuid = () => {
    const newUuid = uuidv4();
    setCrmId(newUuid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const lead = {
      date: new Date().toISOString(),
      crm_id: crmId || uuidv4(),
      nom,
      prenom,
    };
    // console.log("Lead à envoyer:", lead);
    setLeadToSend(lead);
    setCrmId("");
    setNom("");
    setPrenom("");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="grid-container text-black">
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Crm_id</div>
          <input
            as={Row}
            controlId="formCrmId"
            type="text"
            name="firstName"
            className="form-control"
            placeholder=""
            required
            value={crmId}
            onChange={(e) => setCrmId(e.target.value)}
          />
        </div>
        <div className="grid-item p-0 me-1 d-flex align-items-end">
          <div
            onClick={handleGenerateUuid}
            className="p-1 border rounded bg-dark text-white text-center pointer mb-0 col-12"
          >
            Nouveau CRM ID
          </div>
        </div>
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Prénom</div>
          <input
            as={Row}
            type="text"
            name="firstName"
            className="form-control"
            placeholder=""
            required
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
          />
        </div>
        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Nom</div>
          <input
            as={Row}
            type="text"
            name="firstName"
            className="form-control"
            placeholder=""
            required
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
        </div>
        <div className="grid-item p-0 me-1 full-width mt-2">
          <button
            type="submit"
            className="border pointer btn btn-green text-black me-2 col-12"
          >
            Valider
          </button>
        </div>
      </Form>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AddLead;
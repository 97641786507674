import React, { useState, useEffect } from "react";
import { Card, Table, Form } from "react-bootstrap";
import ava1 from "../../../../images/avataaars (1).svg";
import axios from "axios";
import config from "../../../../config/config";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../../Redux/slices/admin.slice";
import { setPopup } from "../../../Redux/slices/actions.slice";
import useHeader from "../../../../utils/useHeader";
import { SVGICON } from "../../../../jsx/constant/theme";

const ActiveSupport = () => {
  const [listUser, setListUser] = useState([]);

  const dispatch = useDispatch();

  const adminDetails = useSelector(getAdminDetails);
  const deskAdmin = adminDetails?.desk;
  const rangAdmin = adminDetails?.rang;
  const { desk } = useParams();

  const Desk = desk || deskAdmin.join(",");

  const Header = useHeader();

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const fetchUsers = () => {
    let obj = {
      desk: Desk,
      categories: "SUPPORT",
      profil: "",
      allusers: true,
      rang: rangAdmin,
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/Users_by_desk_categories_and_profil`,
        obj,
        Header
      )
      .then((response) => {
        setListUser(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DesactiveAdmin = (idAdmin, ischecked) => {
    let confirResponse = window.confirm("Etes vous sur ?");
    if (confirResponse) {
      let obj = {
        id: idAdmin?.idusers, // Utilisation directe de idAdmin si c'est un identifiant simple
        is_active: ischecked ? 1 : 0,
      };
      axios
        .patch(`${config.apiAdmin}/sadmin/users/bool-is-active`, obj, Header)
        .then((response) => {
          SendPopup({
            message: "confirm",
            info: "bool-is-active.",
            isActive: true,
          });
          fetchUsers(); // Assurez-vous que cette fonction est définie pour mettre à jour l'interface utilisateur
        })
        .catch((error) => {
          SendPopup({
            message: "error",
            info: "bool-is-active.",
            isActive: true,
          });
          console.error("There was an error!", error);
        });
    }
  };

  return (
    <>
      <div className="mt-4 border-top pt-2 pb-2 border-bottom">
        <div className="ms-5 fw-bold text-black">
          Activer/desactiver des utilisateurs
        </div>
        <div className="ms-5 text-black">
          Cette page vous permet d’activer et de désactiver des utilisateurs.
        </div>
        <div></div>
      </div>
      <Card className="mt-5" style={{ height: "90%", overflowY: "auto" }}>
        <Table bordered className="table-no-vertical-borders">
          <thead>
            <tr>
              <th>{SVGICON.BiPersonIcon}</th>
              <th>Pseudo</th>
              <th>Profil</th>
              <th>Statut de l’utilisateur</th>
            </tr>
          </thead>
          <tbody>
            {listUser
              ?.sort((a, b) => a.pseudo.localeCompare(b.pseudo))
              .map((member) => {
                return (
                  <tr key={member?.idusers}>
                    <td>
                      <img
                        src={member.avatar || ava1}
                        alt=""
                        style={{ height: "40px" }}
                      />
                    </td>
                    <td>{member?.pseudo}</td>
                    <td className="d-flex justify-content-center align-items-center m-2">
                      <div
                        className={`badge-list-admin badge-${member?.profil} text-white fs-12`}
                      >
                        {member?.profil}
                      </div>
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`switch-${member?.id}`}
                        className="custom-switch"
                        checked={member?.is_active === 1}
                        onChange={(e) => {
                          DesactiveAdmin(member, e.target.checked);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default ActiveSupport;

import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import SideBarAdmin from "../../../PagesCrm/Admin/Layout/SideBar";
import SideBarAgtSupp from "../../../PagesCrm/AgentSupport/SideBar";
import SideBarManager from "../../../PagesCrm/Manager/Layout/SideBar";
import SideBarTlead from "../../../PagesCrm/TeamLeader/Layout/SideBar";
import SideBarAssMng from "../../../PagesCrm/Secretaire/Layout/SideBar";
import SideBarBossSupp from "../../../PagesCrm/BossSupport/Layout/SideBar";
import SideBarMngSupp from "../../../PagesCrm/MngSupport/Layout/SideBar";
import SideBarSell from "../../../PagesCrm/Seller/Layout/SideBar";

import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../../PagesCrm/Redux/slices/admin.slice";

// import logoCrm from "../../../images/logorrrrrrr.jpg";
// import logoSmall from "../../../images/Design sans titre (21) (1).png";
import logoTransparent from "../../../images/Design sans titre (20) (1).png";

// import Lottie from "react-lottie";
// import ChristmasHatAnimation from '../../../images/Animation - 1734454258152.json'; // Chemin vers votre fichier JSON local

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const AdminDetails = useSelector(getAdminDetails);
  const [profilAdmin] = useState(AdminDetails?.profil);
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: ChristmasHatAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  let SelectedSideBar;
  switch (profilAdmin) {
    case "boss_supp":
      SelectedSideBar = <SideBarBossSupp />;
      break;
    case "mng_supp":
      SelectedSideBar = <SideBarMngSupp />;
      break;
    case "agt_supp":
      SelectedSideBar = <SideBarAgtSupp />;
      break;
    case "manager":
      SelectedSideBar = <SideBarManager />;
      break;
    case "tlead":
      SelectedSideBar = <SideBarTlead />;
      break;
    case "admin":
      SelectedSideBar = <SideBarAdmin />;
      break;
    case "ass_mng":
      SelectedSideBar = <SideBarAssMng />;
      break;
    case "sell":
      SelectedSideBar = <SideBarSell />;
      break;
    default:
      SelectedSideBar = <SideBar />;
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-start">
      <div className="nav-header ms-3 mt-3">
          <img src={logoTransparent} alt="Logo Transparent" style={{height:"72px"}}/>
          {/* Animation Lottie dans le coin supérieur droit */}
          <div
            className="position-absolute"
            style={{
              top: "0px",
              right: "20px",
              width: "50px",
              height: "50px",
            }}
          >
            {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
            </div>
        </div>
        <Header
          onNote={() => onClick("chatbox")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle}
          title={title}
          onBox={() => onClick("box")}
          onClick={() => ClickToAddEvent()}
        />
        {SelectedSideBar}
      </div>
    </div>
  );
};

export default JobieNav;